<template>
    <div class="flex flex-col ml-4 text-black">
        <div v-for="(rule, index) in validation.rules" :key="index" class="flex flex-col">
            <div class="flex flex-row items-center">
                <select v-model="rule.type" class="w-auto pr-6 p-2 text-black bg-white border-2 border-gray-300">
                    <option v-for="{ value, text } in rules" :key="value" :value="value">
                        {{ text }}
                    </option>
                </select>
                <Multiselect
                    v-if="rule.type !== 'required'"
                    v-model="rule.options"
                    mode="tags"
                    :options="availableOptions"
                    :close-on-select="false"
                    :searchable="true"
                    :search-filter="customSearchFilter"
                    label="name"
                    track-by="value"
                    class="custom-multiselect w-full"
                />
                <div v-tooltip="'Delete Item'"
                     class="text-white bg-gray-700 flex items-center justify-center p-[0.6rem] hover:bg-gray-800 border-t-2 border-b-2 border-r-2 h-full">
                    <button
                        @click.prevent="removeRule($event, index)"
                        :disabled="validation.rules.length === 1">
                        <TrashIcon class="h-5 w-5 text-red-500"/>
                    </button>
                </div>
                <div v-if="index === validation.rules.length - 1" v-tooltip="'Add Rule'"
                     class="text-white bg-gray-700 flex items-center justify-center p-[0.6rem] hover:bg-gray-800 border-t-2 border-b-2 border-r-2 h-full">
                    <button @click.prevent="addRule($event)">
                        <PlusIcon class="h-5 w-5 text-blue-500"/>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {
    PlusIcon,
    TrashIcon,
} from "@heroicons/vue/24/outline"

export default {
    components: {
        PlusIcon,
        TrashIcon,
    },
    props: {
        validation: {
            type: Object,
            required: true,
        },
        availableOptions: {
            type: Array
        }
    },
    data() {
        return {
            rules: [
                {value: null, text: 'None'},
                {value: 'required', text: 'Required'},
                {value: 'required_with', text: 'Required With'},
                {value: 'required_with_any', text: 'Required With Any'},
                {value: 'required_without', text: 'Required Without'},
                {value: 'required_without_all', text: 'Required Without Any'},
                {value: 'exclude_with', text: 'Exclude With'},
                {value: 'exclude_without', text: 'Exclude Without'},
                {value: 'disabled_with', text: 'Disabled With'},
                {value: 'disabled_without', text: 'Disabled Without'},
            ],
        }
    },
    mounted() {
        if (!this.validation.rules || this.validation.rules.length === 0) {
            this.addRule();
        }
    },
    methods: {
        customSearchFilter(option, query) {
            return option.name.toLowerCase().includes(query.toLowerCase());
        },
        addRule(event) {
            if (event) event.preventDefault();
            if (!this.validation.rules) {
                this.validation.rules = [];
            }
            this.validation.rules.push({type: null, options: []});
        },
        removeRule(event, index) {
            if (event) event.preventDefault();
            if (this.validation.rules.length > 1) {
                this.validation.rules.splice(index, 1);
            }
        }
    }
};
</script>

<style scoped>
.custom-multiselect {
    width: 100%;
}
</style>
