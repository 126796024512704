<template>
    <div>
        <template v-for="(section, sectionIndex) in contents" :key="sectionIndex">
            <template v-if="checkVisibility(section)">
                <template v-for="(item, index) in section.content" :key="index">
                    <ContentItem
                        :index="index"
                        :item="item"
                        :sectionIndex="sectionIndex"
                        :getContent="getContent"
                        :listItemContent="listItemContent"
                        :checkVisibility="checkVisibility"
                        :replaceShortcodes="replaceShortcodes"
                        :getEvaluatedText="getEvaluatedText"/>
                </template>
                <template v-if="section.isRegional">
                    <template v-for="(sections, sectionsIndex) in regionalContents" :key="'regional-' + sectionsIndex">
                        <template v-for="(section, sectionIndex) in sections" :key="'regional-' + sectionIndex">
                            <template v-if="section.type !== 'privacy_contact'"
                                      v-for="(item, index) in section.content"
                                      :key="index">
                                <ContentItem
                                    :index="index"
                                    :item="item"
                                    :sectionIndex="sectionIndex"
                                    :getContent="getContent"
                                    :listItemContent="listItemContent"
                                    :checkVisibility="checkVisibility"
                                    :replaceShortcodes="replaceShortcodes"
                                    :getEvaluatedText="getEvaluatedText"/>
                            </template>
                        </template>
                    </template>
                </template>
            </template>
        </template>
    </div>
</template>

<script>
import ContentItem from './ContentItem.vue';

export default {
    components: {
        ContentItem
    },
    props: {
        slug: {
            type: String,
            required: true
        },
        contents: {
            type: Array,
            default: () => [],
        },
        includedPolicies: {
            type: Array,
            default: () => [],
        },
        regionalContents: {
            type: Array,
            default: () => [],
        },
        options: {
            type: Object,
            default: () => ({}),
        },
        shortcodes: {
            type: Object,
            default: () => ({}),
        },
        preview: {
            type: Boolean,
            default: false,
        },
        cookieTypes: {
            type: Array,
            default: () => [],
        }
    },
    methods: {
        replaceShortcodes(text) {
            if(text){
                text = text.replace(/\[([^\]]+)\]/g, (match, p1) => {
                    const replacement = this.shortcodes[`[${p1}]`];
                    if (replacement) {
                        const urlPattern = /^(https?:\/\/[^\s]+)$/;
                        if (urlPattern.test(replacement)) {
                            return `<a href="${replacement}">${replacement}</a>`;
                        }
                        return `${replacement}`;
                    }
                    return match;
                });
            }
            return text;
        },
        getEvaluatedText(index, item) {
            let text = item.intro.value ? item.intro.value + ' ' : '';
            let contentItems = [];
            item.evaluatedItems.forEach((evaluatedItem) => {
                const content = this.getContent(index, evaluatedItem, true);
                if(content){
                    contentItems.push(content);
                }
            });
            if(contentItems.length > 0){
                text += contentItems.join(', ');
            }
            text += item.outro.value ? ' ' + item.outro.value : '';
            return text;

        },
        getContent(index, item, obscureOnPreview = false) {
            let text = this.getText(item);
            text = this.replaceShortcodes(text);
            if (this.preview && obscureOnPreview) {
                return this.obscureText(index, text);
            }
            return text;
        },
        listItemContent(index, listItems) {
            let items = [];
            listItems.list.items.forEach((listItem) => {
                let text = this.getText(listItem, 'list');
                if (listItem.text?.addOwn) {
                    listItem.settings.options?.forEach((option) => {
                        if (this.options[option]) {
                            const addOwnArray = this.options[option];
                            addOwnArray.forEach((addOwnItem) => {
                                text = this.replaceShortcodes(addOwnItem);
                                if (this.preview) {
                                    text = this.obscureText(index, text);
                                }
                                if(text) {
                                    items.push(`<li>${text}</li>`);
                                }
                            });
                        }
                    });
                } else if (text) {
                    text = this.replaceShortcodes(text);
                    if (this.preview) {
                        text = this.obscureText(index, text);
                    }
                    items.push(`<li>${text}</li>`);
                }
            });
            if (items.length > 0) {
                return items.join('');
            }
        },
        getText(item, type = null) {
            if (!type) {
                type = item.type;
            }
            if (!item.showSettings) {
                if (type === 'header') {
                    return item.header.value;
                }
                if (type === 'text' || type === 'list') {
                    return item.text.value;
                }
            }

            if (item.settings.visibility && item.settings.evaluate) {
                if (this.evaluateVisibility(item.settings)) {
                    if (type === 'header') {
                        return item.header.value;
                    }
                    if (type === 'text' || type === 'list') {
                        return item.text.value;
                    }
                }
            }

            if (item.settings.elseType === "or") {
                for (const orItem of item.settings.orListItems) {
                    if (this.evaluateVisibility(orItem)) {
                        return orItem.orText;
                    }
                }
            }


            if (type === 'list' && item.settings.else === "or") {
                for (const orItem of item.settings.orListItems) {
                    if (this.evaluateVisibility(orItem)) {
                        return orItem.text.value;
                    }
                }

            }

            if (item.settings?.showElse) {
                if (type === 'header') {
                    return item.settings.elseHeader.value;
                }
                if (type === 'text') {
                    return item.settings.elseText.value;
                }
            }

            if (item.settings?.else === 'else' || item.settings?.else === 'or') {
                return item.settings.elseListItem.value;
            }
            return "";
        },
        obscureText(index, item) {
            if (index !== 0 && this.preview) {
                return item.replace(/(<[^>]+>)|([^<>\s])/g, (match, p1, p2) => p1 || '*');
            }
            return item;
        },
        evaluateVisibility(item) {
            const visibility = item.visibility;
            const evaluate = item.evaluate;
            const options = item.options;


            const cookieTypeMapping = {
                'essential': 'hasEssentialCookies',
                'functional': 'hasFunctionalityCookies',
                'marketing': 'hasTargetingAdvertisingCookies',
                'analytics': 'hasPerformanceCookies'
            };

            const transformedCookieTypes = this.cookieTypes.map(type => cookieTypeMapping[type] || type);

            const cookieTypeOptions = [
                'hasEssentialCookies',
                'hasPerformanceCookies',
                'hasFunctionalityCookies',
                'hasTargetingAdvertisingCookies'
            ];

            let hasCookieTypeOption = false;
            if (options) {
                hasCookieTypeOption = options.some(option => cookieTypeOptions.includes(option));
            }

            if (hasCookieTypeOption) {
                let result;
                switch (evaluate) {
                    case 'ifAny':
                        result = options.some(option => transformedCookieTypes.includes(option));
                        break;
                    case 'ifNot':
                        result = options.every(option => !transformedCookieTypes.includes(option));
                        break;
                    case 'ifHas':
                    case 'ifAll':
                        result = options.every(option => transformedCookieTypes.includes(option));
                        break;
                    default:
                        result = false;
                        break;
                }
                return visibility === 'show' ? result : !result;
            }

            if (evaluate && options) {
                let result;
                switch (evaluate) {
                    case 'ifAny':
                        result = options.some(option => this.options[option] || Object.values(this.options).includes(option));
                        break;
                    case 'ifNot':
                        result = options.every(option => !this.options[option] && !Object.values(this.options).includes(option));
                        break;
                    case 'ifHas':
                    case 'ifAll':
                        result = options.every(option => this.options[option] || Object.values(this.options).includes(option));
                        break;
                    case 'ifHasPolicy':
                        result = this.includedPolicies.some(policy => options.includes(policy));
                        break;
                    default:
                        result = false;
                        break;
                }
                return visibility === 'show' ? result : !result;
            }
        },
        checkVisibility(item) {
            if (!item.showSettings) {
                return true;
            }

            let display = this.evaluateVisibility(item.settings);

            if (display) {
                return true;
            }

            if (item.settings.elseType === "or") {
                const orItemVisible = item.settings.orListItems.some((orItem) => {
                    return !!this.evaluateVisibility(orItem);
                });

                if (orItemVisible) {
                    return true;
                }
            }

            return !!(!display && item.settings?.showElse);
        }
    },
    mounted() {
        this.$nextTick(() => {
            const htmlContentElement = document.getElementById(`html-content-${this.slug}`);
            const markdownContentElement = document.getElementById(`markdown-content-${this.slug}`);
            if (htmlContentElement) {
                // Make an AJAX request to process the HTML content with htmLawed
                fetch('/process-html', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
                    },
                    body: JSON.stringify({html: this.$el.innerHTML})
                })
                    .then(response => response.json())
                    .then(data => {
                        htmlContentElement.innerText = data.processedHtml;
                    })
                    .catch(error => console.error('Error:', error));
            }
            if (markdownContentElement) {
                // Make an AJAX request to process the HTML content with htmLawed and convert to Markdown
                fetch('/process-html-to-markdown', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
                    },
                    body: JSON.stringify({html: this.$el.innerHTML})
                })
                    .then(response => response.json())
                    .then(data => {
                        markdownContentElement.innerText = data.processedMarkdown;
                    })
                    .catch(error => console.error('Error:', error));
            }
        })
    }
};
</script>
