<template>
    <div class="flex flex-1 items-stretch">
        <div class="flex items-center justify-center p-2 bg-blue-100 drag-handle max-h-[42px]">
            <ChevronUpDownIcon class="w-6 h-6 text-black"/>
        </div>
        <div v-tooltip="'Add Own'" class="flex justify-center items-center bg-white w-[42px] h-[42px]">
            <input type="checkbox" v-model="text.addOwn" @change="handleAddOwnChange" class="w-6 h-6">
        </div>
        <textarea v-model="text.value" placeholder="Item Content" :readonly="text.addOwn"
                  class="text-black textarea-class flex-1 border-2 border-t-white h-[42px]"></textarea>
    </div>
</template>

<script>
import {ChevronUpDownIcon} from "@heroicons/vue/24/outline/index.js";

export default {
    components: {ChevronUpDownIcon},
    props: {
        text: Object,
        id: String
    },
    data(){
        return {
            textValue: ''
        }
    },
    methods: {
        handleAddOwnChange(){
            if(this.text.addOwn){
                this.textValue = this.text.value;
                this.text.value = "Add Your Own";
            } else {
                this.text.value = this.textValue;
            }
        }
    }
}
</script>
