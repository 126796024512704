<template>
    <div :key="group.id" class="flex-1 ml-4 mt-4">
        <div class="flex items-stretch mt-2">
            <div class="flex items-center justify-center p-2 bg-blue-100 rounded-l-lg drag-handle">
                <ChevronUpDownIcon class="w-6 h-6 text-black"/>
            </div>
            <input v-model="group.name"
                   class="text-black p-2 border-2 w-full border-white border-l-gray-800"
                   :placeholder="'Sub Group ' + (itemGroupIndex + 1) + ' Name (Optional)'">
            <div v-tooltip="'Add Item'"
                 class="text-white bg-gray-700 flex items-center justify-center p-2 hover:bg-gray-800 border-t-2 border-b-2 border-r-2">
                <button @click.prevent="addItemGroupItem(stepIndex, optionIndex, groupIndex, itemIndex, itemGroupIndex)">
                    <ListBulletIcon class="h-5 w-5 text-green-500"/>
                </button>
            </div>
            <div v-tooltip="(group.contentVisible ? 'Hide' : 'Show') + ' group content'"
                 class="text-white bg-gray-700 flex items-center justify-center p-2 hover:bg-gray-800 border-t-2 border-b-2 border-r-2">
                <button
                    @click.prevent="toggleItemGroupContentVisibility(stepIndex, optionIndex, groupIndex, itemIndex, itemGroupIndex)">
                    <ArrowUpTrayIcon v-if="group.contentVisible"
                                     class="h-5 w-5 text-white"/>
                    <ArrowDownTrayIcon v-else class="h-5 w-5 text-white"/>
                </button>
            </div>
            <div v-tooltip="'Delete group'"
                 class="text-white bg-gray-700 flex items-center justify-center p-2 hover:bg-gray-800 border-t-2 border-b-2 border-r-2">
                <button @click.prevent="deleteItemGroup(stepIndex, optionIndex, groupIndex, itemIndex, itemGroupIndex)">
                    <!-- ListBulletIcon component -->
                    <TrashIcon class="h-5 w-5 text-red-500"/>
                </button>
            </div>
        </div>
        <transition name="expand" @before-enter="beforeEnter" @enter="enter"
                    @leave="leave">
            <div v-if="group.contentVisible">
                <ul>
                    <draggable v-model="group.items" item-key="id" @start="drag=true" @end="drag=false">
                        <template #item="{ element: item, index: index }">
                            <ItemGroupItemComponent
                                :key="item.id"
                                :item="item"
                                :stepIndex="stepIndex"
                                :optionIndex="optionIndex"
                                :groupIndex="groupIndex"
                                :itemIndex="itemIndex"
                                :itemGroupIndex="itemGroupIndex"
                                :itemGroupItemIndex="index"
                                :content="content"
                            />
                        </template>
                    </draggable>
                </ul>
            </div>
        </transition>
    </div>
</template>

<script>
import draggable from 'vuedraggable';

import {
    ArchiveBoxXMarkIcon,
    ArrowDownTrayIcon,
    ArrowRightCircleIcon,
    ArrowUpTrayIcon,
    ChatBubbleLeftIcon,
    CheckCircleIcon,
    ChevronUpDownIcon,
    TrashIcon,
    ListBulletIcon,
    PlusCircleIcon
} from "@heroicons/vue/24/outline"
import ItemGroupItemComponent from "./ItemGroupItemComponent.vue";

export default {
    components: {
        ItemGroupItemComponent,
        ArchiveBoxXMarkIcon,
        ArrowDownTrayIcon,
        ArrowRightCircleIcon,
        ArrowUpTrayIcon,
        ChatBubbleLeftIcon,
        CheckCircleIcon,
        ChevronUpDownIcon,
        TrashIcon,
        ListBulletIcon,
        PlusCircleIcon,
        draggable
    },
    props: {
        group: {
            type: Object,
            required: true,
        },
        stepIndex: {
            type: Number,
            required: true,
        },
        optionIndex: {
            type: Number,
            required: true,
        },
        groupIndex: {
            type: Number,
            required: true,
        },
        itemIndex: {
            type: Number,
            required: true,
        },
        itemGroupIndex: {
            type: Number,
            required: true
        },
        content: {
            type: Array,
            default: () => [],
        }
    },
    data() {
        return {
            drag: false
        };
    },
    inject: [
        'addItemGroupItem',
        'toggleItemGroupContentVisibility',
        'deleteItemGroup',
    ],
    methods: {
        beforeEnter(el) {
            el.style.height = '0';
            el.style.opacity = '0';
        },
        enter(el, done) {
            this.$nextTick(() => {
                el.style.transition = 'height 0.5s ease';
                el.style.height = `${el.scrollHeight}px`;
                el.style.opacity = '1';
                el.addEventListener('transitionend', () => {
                    el.style.height = null;
                    done();
                });
            });
        },
        leave(el, done) {
            el.style.height = `${el.scrollHeight}px`;
            el.style.opacity = '1';
            getComputedStyle(el).height;
            setTimeout(() => {
                el.style.transition = 'height 0.5s ease';
                el.style.height = '0';
                el.style.opacity = '0';
                el.addEventListener('transitionend', done);
            });
        },
    },
};
</script>
