<template>
    <li :key="itemIndex">
        <div id="parentWrapper" class="flex-1 ml-4 mt-4">
            <div class="flex items-stretch mt-2">
                <div class="flex items-center justify-center p-2 bg-blue-100 rounded-l-lg drag-handle">
                    <ChevronUpDownIcon class="w-6 h-6 text-black"/>
                </div>
                <select v-model="item.type" class="text-black self-start border-white border-l-gray-800 h-[44px]"
                        @change="selectUpdate">
                    <option v-for="{ value, text } in typeOptions" :key="value" :value="value">
                        {{ text }}
                    </option>
                </select>
                <select v-if="item.type === 'checkbox' || item.type === 'addOwn'" v-model="item.span"
                        class=" h-[44px] text-black self-start border-white border-l-gray-800">
                    <option v-for="{ value, text } in spans" :key="value" :value="value">
                        {{ text }}
                    </option>
                </select>
                <div class="flex flex-col w-full self-start">
                    <input v-model="item.name"
                           v-if="item.type !== 'radio'"
                           :disabled="item.type === 'addOwn' || item.type === 'singleCountry'"
                           class="disabled:bg-gray-300 text-black p-2 border-2 w-full border-white border-l-gray-800"
                           :placeholder="itemLabel + ' Label'">
                </div>
                <div v-if="item.type === 'radio'"
                     v-tooltip="'Radio Group Width'"
                     class="flex flex-col self-start">
                    <select v-model="item.width" class="text-black self-start border-white border-l-gray-800 h-[44px]">
                        <option value="full">Full</option>
                        <option value="half">1/2</option>
                        <option value="third">1/3</option>
                    </select>
                </div>
                <div v-tooltip="'Validation'"
                     @click.prevent="toggleItemValidation(stepIndex, optionIndex, groupIndex, itemIndex)"
                     :class="[
                         'flex items-center justify-center p-2 hover:bg-gray-800 border-t-2 border-b-2 border-r-2',
                         item.type === 'radio' ? 'border-l-2' : '',
                         item.validationVisible ? 'bg-green-600' : 'bg-white'
                         ]">
                    <button >
                        <CheckIcon :class="['h-5 w-5', item.validationVisible ? 'text-white' : 'text-green-500']"/>
                    </button>
                </div>
                <div v-tooltip="'Default State'"
                     v-if="item.type === 'checkbox'"
                     class="text-white bg-gray-700 flex items-center justify-center p-2 hover:bg-gray-800 border-t-2 border-b-2 border-r-2">
                    <div :class="['flex items-center justify-center h-5 w-5']">
                        <input
                            type="checkbox"
                            checked
                            v-if="item.defaultChecked"
                            @change="toggleItemDefaultState(stepIndex, optionIndex, groupIndex, itemIndex)"
                            class="form-checkbox h-4 w-4 text-gray-600 border-gray-300 rounded"
                        />
                        <input
                            type="checkbox"
                            v-if="!item.defaultChecked"
                            @change="toggleItemDefaultState(stepIndex, optionIndex, groupIndex, itemIndex)"
                            class="form-checkbox h-4 w-4 text-gray-600 border-gray-300 rounded"
                        />
                    </div>
                </div>
                <div v-if="item.type === 'textarea' || item.type === 'checkbox' || item.type === 'dataProtection'"
                     v-tooltip="(item.showDescription ? 'Remove ' : 'Add ') + itemLabel + ' description'"
                     class="text-white bg-gray-700 flex items-center justify-center p-2 hover:bg-gray-800 border-t-2 border-b-2 border-r-2">
                    <button @click.prevent="toggleItemDescription(stepIndex, optionIndex, groupIndex, itemIndex)">
                        <ChatBubbleLeftIcon class="h-5 w-5 text-white"/>
                    </button>
                </div>
                <div v-if="item.type === 'checkbox'"
                     v-tooltip="'Add Item Group'"
                     class="text-white bg-gray-700 flex items-center justify-center p-2 hover:bg-gray-800 border-t-2 border-b-2 border-r-2">
                    <button @click.prevent="addItemGroup(stepIndex, optionIndex, groupIndex, itemIndex)">
                        <ListBulletIcon class="h-5 w-5 text-green-500"/>
                    </button>
                </div>
                <div v-if="item.type === 'checkbox'"
                     v-tooltip="'Add Display Text'"
                     class="text-white bg-gray-700 flex items-center justify-center p-2 hover:bg-gray-800 border-t-2 border-b-2 border-r-2">
                    <button @click.prevent="toggleItemDisplayText(stepIndex, optionIndex, groupIndex, itemIndex)">
                        <DocumentTextIcon class="h-5 w-5 text-green-500"/>
                    </button>
                </div>
                <div v-if="item.type === 'radio'"
                     v-tooltip="'Add Radio Option'"
                     class="text-white bg-gray-700 flex items-center justify-center p-2 hover:bg-gray-800 border-t-2 border-b-2 border-r-2 border-l-2">
                    <button @click.prevent="addRadioOption(stepIndex, optionIndex, groupIndex, itemIndex)">
                        <ListBulletIcon class="h-5 w-5 text-green-500"/>
                    </button>
                </div>
                <div v-if="item.type === 'radio' || item.showDescription || (item.groups && item.groups.length)"
                     v-tooltip="(item.contentVisible ? 'Hide' : 'Show') + ' item content'"
                     class="text-white bg-gray-700 flex items-center justify-center p-2 hover:bg-gray-800 border-t-2 border-b-2 border-r-2">
                    <button
                        @click.prevent="toggleItemContentVisibility(stepIndex, optionIndex, groupIndex, itemIndex)">
                        <ArrowUpTrayIcon v-if="item.contentVisible"
                                         class="h-5 w-5 text-white"/>
                        <ArrowDownTrayIcon v-else class="h-5 w-5 text-white"/>
                    </button>
                </div>
                <div v-tooltip="'Delete Item'"
                     class="text-white bg-gray-700 flex items-center justify-center p-2 hover:bg-gray-800 border-t-2 border-b-2 border-r-2">
                    <button
                        @click.prevent="removeItem(stepIndex, optionIndex, groupIndex, itemIndex);">
                        <TrashIcon class="h-5 w-5 text-red-500"/>
                    </button>
                </div>
            </div>
        </div>
        <ValidationComponent
            v-if="item.validationVisible"
            v-model="item.validation"
            :validation="item.validation"
            :available-options="availableOptions">
        </ValidationComponent>
        <div v-if="item.contentVisible" class="flex-1 ml-4">
            <input v-model="item.description"
                   v-if="item.showDescription"
                   class="disabled:bg-gray-300 text-black p-2 border-2 w-full border-white border-t-gray-800"
                   :placeholder="(item.type === 'textarea' ? 'Placeholder/Default Text' : 'Description') + ' for ' + (itemLabel)">
            <div class="flex"
                 v-if="
                 item.type === 'textarea' ||
                 item.type === 'text' ||
                 item.type === 'integer' ||
                 item.type === 'phone' ||
                 item.type === 'email' ||
                 item.type === 'url' ||
                 item.type === 'multiCountry' ||
                 item.type === 'radio'
                ">
                <div class="bg-white border-t-2 border-r-2 border-black text-black p-2">Shortcode:</div>
                <input v-model="item.shortcode"
                       class="disabled:bg-gray-300 text-black p-2 border-2 w-full border-white border-t-gray-800"
                       :placeholder="'Shortcode for ' + (itemLabel)">
            </div>
            <transition name="expand" @before-enter="beforeEnter" @enter="enter"
                        @leave="leave">
                <div v-if="item.contentVisible">
                    <ul>
                        <draggable v-model="item.groups" item-key="id" @start="drag=true" @end="drag=false">
                            <template #item="{ element, index }">
                                <ItemGroupComponent
                                    :key="element.id"
                                    :group="element"
                                    :stepIndex="stepIndex"
                                    :optionIndex="optionIndex"
                                    :groupIndex="groupIndex"
                                    :itemIndex="itemIndex"
                                    :itemGroupIndex="index"
                                    :content="content"
                                />
                            </template>
                        </draggable>
                    </ul>
                    <draggable v-if="item.type === 'radio'"
                               v-model="item.radioOptions"
                               item-key="id"
                               @start="drag=true" @end="drag=false"
                               handle=".drag-handle">
                        <template #item="{ element: radio, index: radioIndex }">
                            <RadioComponent
                                :key="radio.id"
                                :radio="radio"
                                :radioIndex="radioIndex"
                                :radioCount="item.radioOptions.length"
                                :stepIndex="stepIndex"
                                :optionIndex="optionIndex"
                                :groupIndex="groupIndex"
                                :itemIndex="itemIndex"
                            />
                        </template>
                    </draggable>
                </div>
            </transition>
        </div>
        <div v-if="item.showDisplayText" class="flex-1 ml-4">
            <div class="p-2 mt-1">Display text (shown if item is selected)</div>
            <div v-for="language in languages" :key="language.slug" class="flex">
                <div class="w-1/6 p-2 bg-white text-black border-t-2 border-black border-r-2" style="white-space: nowrap;">
                    {{ language.slug }}:
                </div>
                <input v-model="item.displayText[language.slug]"
                       class="disabled:bg-gray-300 text-black p-2 border-2 w-full border-white border-t-gray-800"
                       :placeholder="'Text to display if ' + itemLabel + ' selected'">
            </div>
            <div class="flex">
                <div class="bg-white border-t-2 border-r-2 border-black text-black p-2">Shortcode:</div>
                <input v-model="item.shortcode"
                       class="disabled:bg-gray-300 text-black p-2 border-2 w-full border-white border-t-gray-800"
                       :placeholder="'Shortcode for ' + (itemLabel)">
            </div>
        </div>
    </li>
</template>

<script>

import {
    ArchiveBoxXMarkIcon,
    ArrowDownTrayIcon,
    ArrowRightCircleIcon,
    ArrowUpTrayIcon,
    ChatBubbleLeftIcon,
    CheckCircleIcon,
    CheckIcon,
    ChevronUpDownIcon,
    TrashIcon,
    ListBulletIcon,
    PlusCircleIcon,
    DocumentTextIcon
} from "@heroicons/vue/24/outline"
import RadioComponent from "./RadioComponent.vue";
import draggable from "vuedraggable";
import ItemGroupComponent from "./ItemGroupComponent.vue";
import ValidationComponent from "./ValidationComponent.vue";

export default {
    components: {
        ValidationComponent,
        CheckIcon,
        ItemGroupComponent,
        draggable,
        RadioComponent,
        ArchiveBoxXMarkIcon,
        ArrowDownTrayIcon,
        ArrowRightCircleIcon,
        ArrowUpTrayIcon,
        ChatBubbleLeftIcon,
        CheckCircleIcon,
        ChevronUpDownIcon,
        TrashIcon,
        ListBulletIcon,
        PlusCircleIcon,
        DocumentTextIcon,
    },
    props: {
        item: {
            type: Object,
            required: true,
        },
        stepIndex: {
            type: Number,
            required: true
        },
        optionIndex: {
            type: Number,
            required: true
        },
        groupIndex: {
            type: Number,
            required: true
        },
        itemIndex: {
            type: Number,
            required: true
        },
        content: {
            type: Array,
            default: () => [],
        },
        languages: {
            type: Array,
            default: () => [],
        }
    },
    data() {
        return {
            drag: false,
            textInitial: '',
            typeLabels: {
                checkbox: 'Checkbox',
                radio: 'Radio Group',
                addOwn: 'Add Own',
                text: 'Text Input',
                textarea: 'Textarea',
                integer: 'Integer',
                phone: 'Phone',
                email: 'Email',
                url: 'URL',
                multiCountry: 'Country (Multi)',
                singleCountry: 'Country (Single)',
                dataProtection: 'Data Protection Contact'
            },
            spans: [
                {value: 'half', text: 'Half'},
                {value: 'full', text: 'Full'},
            ],
        };
    },
    computed: {
        typeOptions() {
            return Object.entries(this.typeLabels).map(([value, text]) => ({value, text}));
        },
        itemLabel() {
            return this.typeLabels[this.item.type] || 'Unknown';
        },
        availableOptions() {
            let optionsMap = {};
            this.content.forEach(step => {
                step.options.forEach(option => {
                    if (option.name && !optionsMap[option.id]) {
                        optionsMap[option.id] = {
                            value: option.id,
                            name: option.name
                        };
                    }
                    option.groups.forEach(group => {
                        group.items.forEach(item => {
                            if (item.name && !optionsMap[item.id]) {
                                optionsMap[item.id] = {
                                    value: item.id,
                                    name: option.name + (group.name ? ' - ' + group.name + ' - ' : ' - ') + item.name
                                };
                            }
                            item.groups.forEach(itemGroup => {
                                itemGroup.items.forEach(itemGroupItem => {
                                    if (itemGroupItem.name && !optionsMap[itemGroupItem.id]) {
                                        optionsMap[itemGroupItem.id] = {
                                            value: itemGroupItem.id,
                                            name: option.name + (group.name ? ' - ' + group.name + ' - ' : ' - ') + item.name + ' - ' + itemGroupItem.name
                                        };
                                    }
                                });
                            })
                            item.radioOptions.forEach(radioOption => {
                                if (radioOption.name && !optionsMap[radioOption.id]) {
                                    optionsMap[radioOption.id] = {
                                        value: radioOption.id,
                                        name: option.name + ' - ' + group.name + ' - ' + radioOption.name
                                    };
                                }
                            })
                        });
                    });
                });
            });
            return Object.values(optionsMap);
        }
    },
    inject: [
        'removeItem',
        'toggleItemDescription',
        'toggleItemDefaultState',
        'addRadioOption',
        'addItemGroup',
        'toggleItemContentVisibility',
        'toggleItemValidation',
        'toggleItemDisplayText'
    ],
    methods: {
        selectUpdate() {
            if (this.item.type === 'radio') {
                this.addRadioOption(this.stepIndex, this.optionIndex, this.groupIndex, this.itemIndex, 2 - this.item.radioOptions.length);
            }
        },
        beforeEnter(el) {
            el.style.height = '0';
            el.style.opacity = '0';
        },
        enter(el, done) {
            this.$nextTick(() => {
                el.style.transition = 'height 0.5s ease';
                el.style.height = `${el.scrollHeight}px`;
                el.style.opacity = '1';
                el.addEventListener('transitionend', () => {
                    el.style.height = null;
                    done();
                });
            });
        },
        leave(el, done) {
            el.style.height = `${el.scrollHeight}px`;
            el.style.opacity = '1';
            getComputedStyle(el).height;
            setTimeout(() => {
                el.style.transition = 'height 0.5s ease';
                el.style.height = '0';
                el.style.opacity = '0';
                el.addEventListener('transitionend', done);
            });
        }
    },
    watch: {
        'item.type': function (newVal, oldVal) {
            if (newVal === 'addOwn') {
                this.textInitial = this.item.name;
                this.item.name = 'Add Own';
            } else if (oldVal === 'addOwn') {
                this.item.name = this.textInitial;
            } else if (oldVal === 'textarea') {
                this.item.showDescription = false;
            }
        },
    },
};
</script>
