<template>
    <div class="flex flex-1 items-stretch">
        <textarea ref="textArea" v-model="text.value" placeholder="Item Content" class="textarea-class flex-1 border-2 border-t-white h-100"></textarea>

    </div>
</template>

<script>
export default {
    props: {
        text: Object,
        id: String
    },
}
</script>
