<template>
    <div :class="['field', 'field--checkbox', { 'is-active': hasContact }]">
        <input type="checkbox"
               :id="'protection-contact[' + policyType + '][has-contact]'"
               :name="'protection-contact[' + policyType + '][has-contact]'"
               v-model="hasContact"
               @change="toggleContactFields">
        <label v-if="item.name" :for="'protection-contact[' + policyType + '][has-contact]'">
            {{ item.name }}
        </label>
        <p v-if="item.showDescription && item.description" class="description">
            {{ item.description }}
        </p>
    </div>
    <div class="form-toggle">
        <div class="fields">
            <div class="field field--checkbox">
                <input :id="'protection-contact[' + policyType + '][same-as-privacy]'"
                       class="contact-widget-disable"
                       v-model="sameAsPrivacy"
                       :name="'protection-contact[' + policyType + '][same-as-privacy]'"
                       type="checkbox"
                       value="yes">
                <label :for="'protection-contact[' + policyType + '][same-as-privacy]'">Same as privacy contact</label>
            </div>
            <div class="field">
                <label :for="'protection-contact[' + policyType + '][name]'">Protection Contact Name*</label>
                <input type="text"
                       :name="'protection-contact[' + policyType + '][name]'"
                       v-model="protectionContactName"
                       :disabled="sameAsPrivacy"
                       class=""
                       data-error-input="protection-contact.name">
                <span v-if="errors['protection-contact[' + policyType + '][name]']" class="field-error error">
                    {{ errors['protection-contact[' + policyType + '][name]'][0] }}
                </span>
            </div>
        </div>
        <fieldset>
            <legend>Contact method for your Data Protection Officer</legend>
            <div class="fields" data-grid="third">
                <!-- Online Form / Page -->
                <div class="field field--radio field--third">
                    <input type="radio"
                           :name="'protection-contact[' + policyType + '][contact-type]'"
                           value="online-form-page"
                           :id="'protection-contact-type-'+policyType+'--online-form-page'"
                           v-model="selectedContactType"
                           :disabled="sameAsPrivacy">
                    <label :for="'protection-contact-type-'+policyType+'--online-form-page'">
                        <span>Online form / page</span>
                    </label>
                </div>

                <!-- Email / Phone -->
                <div class="field field--radio field--third">
                    <input type="radio"
                           :name="'protection-contact[' + policyType + '][contact-type]'"
                           value="email-phone"
                           :id="'protection-contact-type-'+policyType+'--email-phone'"
                           v-model="selectedContactType"
                           :disabled="sameAsPrivacy">
                    <label :for="'protection-contact-type-'+policyType+'--email-phone'">
                        <span>Email / Phone</span>
                    </label>
                </div>

                <!-- Other -->
                <div class="field field--radio field--third">
                    <input type="radio"
                           :name="'protection-contact[' + policyType + '][contact-type]'"
                           value="other"
                           :id="'protection-contact-type-'+policyType+'--other'"
                           v-model="selectedContactType"
                           :disabled="sameAsPrivacy">
                    <label :for="'protection-contact-type-'+policyType+'--other'">
                        <span>Other</span>
                    </label>
                </div>
            </div>

            <!-- Online Form / Page input field -->
            <div class="field field--full contact-widget-input protection-contact__type--online-form-page"
                 :class="{ hide: selectedContactType !== 'online-form-page' }">
                <label :for="'protection-contact[' + policyType + '][contact-online-form-page]'">Add URL</label>
                <input type="text"
                       :name="'protection-contact[' + policyType + '][contact-online-form-page]'"
                       v-model="contactOnlineFormPage"
                       :disabled="sameAsPrivacy"
                />
                <span v-if="errors['protection-contact[' + policyType + '][contact-online-form-page]']"
                      class="field-error error">
                    {{ errors['protection-contact[' + policyType + '][contact-online-form-page]'][0] }}
                </span>
            </div>

            <!-- Email / Phone input field -->
            <div class="field field--full contact-widget-input protection-contact__type--email-phone"
                 :class="{ hide: selectedContactType !== 'email-phone' }">
                <label :for="'protection-contact[' + policyType + '][contact-email-phone]'">Add Email/Phone</label>
                <input type="text"
                       :name="'protection-contact[' + policyType + '][contact-email-phone]'"
                       v-model="contactEmailPhone"
                       placeholder="Add Email/Phone"
                       :disabled="sameAsPrivacy"
                />
                <span v-if="errors['protection-contact[' + policyType + '][contact-email-phone]']" class="field-error error">
                    {{ errors['protection-contact[' + policyType + '][contact-email-phone]'][0] }}
                </span>
            </div>

            <!-- Other input field -->
            <div class="field field--full contact-widget-input protection-contact__type--other"
                 :class="{ hide: selectedContactType !== 'other' }">
                <label :for="'protection-contact[' + policyType + '][contact-other]'">Add Other</label>
                <input type="text"
                       :name="'protection-contact[' + policyType + '][contact-other]'"
                       v-model="contactOther"
                       placeholder="Add Other"
                       :disabled="sameAsPrivacy"
                />
                <span v-if="errors['protection-contact[' + policyType + '][contact-other]']" class="field-error error">
                    {{ errors['protection-contact[' + policyType + '][contact-other]'][0] }}
                </span>
            </div>
        </fieldset>
    </div>
</template>

<script>
export default {
    props: {
        item: {
            type: Object,
            default: () => ({}),
        },
        policyType: {
            type: String,
            default: '',
        },
        errors: {
            type: Object,
            default: () => ({}),
        },
        old: {
            type: Object,
            default: () => ({}),
        }
    },
    data() {
        return {
            hasContact: false,
            sameAsPrivacy: false,
            selectedContactType: 'online-form-page', // default to online form
            protectionContactName: '',
            contactOnlineFormPage: 'https://',
            contactEmailPhone: '',
            contactOther: ''
        };
    },
    mounted() {
        if (this.old && this.old['protection-contact'] && this.old['protection-contact'][this.policyType]) {
            const oldData = this.old['protection-contact'][this.policyType];
            this.hasContact = oldData['has-contact'] === 'on';
            this.sameAsPrivacy = oldData['same-as-privacy'] === 'yes';
            this.protectionContactName = oldData['name'] ?? '';
            this.selectedContactType = oldData['contact-type'] ?? 'online-form-page';
            this.contactOnlineFormPage = oldData['contact-online-form-page'] ?? 'https://';
            this.contactEmailPhone = oldData['contact-email-phone'] ?? '';
            this.contactOther = oldData['contact-other'] ?? '';
        }
    },
    methods: {
        toggleContactFields() {
            if (!this.hasContact) {
                this.sameAsPrivacy = false;
                this.selectedContactType = 'online-form-page';
                this.protectionContactName = '';
                this.contactOnlineFormPage = 'https://';
                this.contactEmailPhone = '';
                this.contactOther = '';
            }
        }
    }
};
</script>
