<template>
    <h2 v-if="step.name" class="">{{ step.name }}</h2>
    <p v-if="step.descriptionVisible">{{ step.description }}</p>
    <template v-for="option in step.options"
              :key="option.id">
        <div class="field field--checkbox"
             :ref="option.id"
             :class="{ 'field--is-disabled': isDisabledOnForm(option), 'is-active': !isDisabledOnForm(option) && isPrimaryOptionSelected(option) && option.groups.length }">
            <input v-if="option.name"
                   type="checkbox"
                   :id="option.id"
                   :name="option.id"
                   @change="($event) => { toggleGroups(option.id, $event); selectOption(option.id, null, $event); }"
                   :checked="!isDisabledOnForm(option) && isPrimaryOptionSelected(option)"
                   @click="toggleActive(option)"
                   :value="regionalId ? 'regional-' + regionalId : option.id">
            <label v-if="option.name" :for="option.id">{{ option.name }}</label>
            <p class="description" v-if="option.descriptionVisible">{{ option.description }}</p>
            <p v-if="isDisabledOnForm(option)" class="subcontent">Comprehensive Policies Only. <a href="/create/step1">Upgrade</a>
            </p>
        </div>
        <div v-if="option.groups.length && !isDisabledOnForm(option)" :ref="option.id + '-groups'"
             class="form-toggle"
             :style="(!isPrimaryOptionSelected(option)) ? 'display: none' : ''">
            <fieldset v-for="group in option.groups" :key="group.id">
                <legend v-if="group.name">{{ group.name }}</legend>
                <div class="fields" :data-grid="[group.items[0].span]">
                    <template v-for="item in group.items" :key="item.id">
                        <template
                            v-if="item.type !== 'multiCountry' && (item.type === 'checkbox' || item.type === 'addOwn')">
                            <div
                                :class="['field', 'field--checkbox', { 'field--half': group.items[0].span === 'half' }]">
                                <input type="checkbox"
                                       :id="item.id"
                                       :name="item.id"
                                       :data-error-input="item.id"
                                       :value="item.id"
                                       :class="[item.type === 'addOwn' ? 'toggle--custom-trigger' : '']"
                                       @change="($event) => {
                                           toggleGroups(item.id, $event);
                                           selectOption(item.id, item.type, $event);
                                       }"
                                       :checked="isOptionSelected(item)">
                                <label v-if="item.name" :for="item.id">
                                    {{ item.type === 'addOwn' ? 'Other (add your own)' : item.name }}
                                </label>
                                <span v-if="errors[item.id]" class="field-error">
                                    {{ errors[item.id][0] }}
                                </span>
                                <p v-if="item.showDescription" class="description">
                                    {{ item.description }}
                                </p>
                            </div>
                            <div v-if="item.type === 'addOwn' && customInputs[item.id]"
                                 class="field field--full add-your-own toggle--custom-target"
                                 :data-custom-target="item.id"
                                 :data-name="item.id">
                                <h3 class="title">Add your own</h3>
                                <ul class="add-your-own__list">
                                    <li v-for="(inputValue, index) in customInputs[item.id]"
                                        :key="`input-${item.id}-${index}`">
                                        <label>
                                            <input type="text"
                                                   class="add-your-own__input 64"
                                                   v-model="customInputs[item.id][index]"
                                                   @input="updateCustomInput(item.id, index, $event.target.value)"
                                                   @keydown.enter.prevent="addCustomInput(item.id, index)"
                                                   :data-option-id="item.id"
                                                   :data-index="index"
                                                   :name="`${item.id}[]`">
                                        </label>
                                        <button type="button"
                                                class="add-your-own__trigger add-your-own__add"
                                                @click.prevent="addCustomInput(item.id, index)">
                                            <span class="icon"></span>
                                            <span class="hidden">Add</span>
                                        </button>
                                        <button
                                            v-if="customInputs[item.id].length > 1"
                                            type="button"
                                            class="add-your-own__trigger add-your-own__remove"
                                            @click.prevent="deleteCustomInput(item.id, index)">
                                            <span class="icon"></span>
                                            <span class="hidden">Remove</span>
                                        </button>
                                    </li>
                                </ul>
                            </div>
                            <div v-if="item.groups.length" :ref="item.id + '-groups'"
                                 class="form-toggle"
                                 style="display: none;">
                                <fieldset v-for="group in item.groups" :key="group.id">
                                    <legend v-if="group.name">
                                        {{ group.name }}
                                    </legend>
                                    <div class="fields" :data-grid="group.items[0].span === 'half' ? 'half' : ''">
                                        <template v-for="item in group.items" :key="item.id">
                                            <div v-if="item.type === 'checkbox' || item.type === 'addOwn'"
                                                 :class="['field field--checkbox ', { 'field--half': item.span === 'half' }]">
                                                <input type="checkbox"
                                                       :id="item.id"
                                                       :name="item.id"
                                                       @change="($event) => { toggleGroups(item.id, $event); selectOption(item.id, item.type, $event); }"
                                                       :checked="isOptionSelected(item)">
                                                <label v-if="item.name" :for="item.id">
                                                    {{
                                                        item.type === 'addOwn' ? 'Other (add your own)' : item.name
                                                    }}
                                                </label>
                                                <span v-if="errors[item.id]" class="field-error">
                                                    {{ errors[item.id][0] }}
                                                </span>
                                                <p v-if="item.showDescription" class="description">
                                                    {{ item.description }}
                                                </p>
                                            </div>
                                            <div v-if="item.type === 'addOwn' && customInputs[item.id]"
                                                 class="field field--full add-your-own toggle--custom-target"
                                                 :data-custom-target="item.id"
                                                 :data-name="item.id">
                                                <h3 class="title">Add your own</h3>
                                                <ul class="add-your-own__list">
                                                    <li v-for="(inputValue, index) in customInputs[item.id]"
                                                        :key="`input-${item.id}-${index}`">
                                                        <label>
                                                            <input type="text"
                                                                   class="add-your-own__input 121"
                                                                   v-model="customInputs[item.id][index]"
                                                                   @input="updateCustomInput(item.id, index, $event.target.value)"
                                                                   @keydown.enter.prevent="addCustomInput(item.id, index)"
                                                                   :data-option-id="item.id"
                                                                   :data-index="index"
                                                                   :name="`${item.id}[]`">
                                                        </label>
                                                        <button type="button"
                                                                class="add-your-own__trigger add-your-own__add"
                                                                @click.prevent="addCustomInput(item.id, index)">
                                                            <span class="icon"></span>
                                                            <span class="hidden">Add</span>
                                                        </button>
                                                        <button v-if="customInputs[item.id].length > 1"
                                                                type="button"
                                                                class="add-your-own__trigger add-your-own__remove"
                                                                @click.prevent="deleteCustomInput(item.id, index)">
                                                            <span class="icon"></span>
                                                            <span class="hidden">Remove</span>
                                                        </button>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div v-if="item.type === 'integer'"
                                                 class="field">
                                                <label :for="item.id">{{ item.name }}</label>
                                                <p v-if="item.showDescription" class="description">
                                                    {{ item.description }}
                                                </p>
                                                <input type="text"
                                                       :name="item.id"
                                                       :id="item.id"
                                                       v-model="contentData[item.id]"
                                                       :disabled="isDisabled(item)"
                                                />
                                                <span v-if="errors[item.id]" class="field-error">
                                                    {{ errors[item.id][0] }}
                                                </span>
                                            </div>
                                        </template>
                                    </div>
                                </fieldset>
                            </div>
                        </template>
                        <div v-if="item.type === 'text'"
                             class="field">
                            <label :for="item.id">{{ item.name }}</label>
                            <input type="text"
                                   :name="item.id"
                                   :id="item.id"
                                   v-model="contentData[item.id]"
                                   :disabled="isDisabled(item)"
                            />
                            <span v-if="errors[item.id]" class="field-error">
                                {{ errors[item.id][0] }}
                            </span>
                            <p v-if="item.showDescription" class="description">
                                {{ item.description }}
                            </p>
                        </div>
                        <div v-if="item.type === 'url'"
                             class="field">
                            <label :for="item.id">{{ item.name }}</label>
                            <input type="url"
                                   :name="item.id"
                                   :id="item.id"
                                   v-model="contentData[item.id]"
                                   :disabled="isDisabled(item)"
                            />
                            <span v-if="errors[item.id]" class="field-error">
                                {{ errors[item.id][0] }}
                            </span>
                            <p v-if="item.showDescription" class="description">
                                {{ item.description }}
                            </p>
                        </div>
                        <TextArea
                            v-if="item.type === 'textarea'"
                            :item="item"
                            :old="old"
                            :errors="errors"
                            :isDisabled="isDisabled(item)"
                        >
                        </TextArea>
                        <fieldset v-if="item.type === 'radio'">
                            <RadioOptions
                                :itemId="item.id"
                                :width="item.width"
                                :radioOptions="item.radioOptions"
                                :old="old"
                                :errors="errors"
                                :isDisabled="isDisabled(item)"
                            />
                        </fieldset>
                        <div v-if="item.type === 'integer'"
                             class="field">
                            <label :for="item.id">{{ item.name }}</label>
                            <p v-if="item.showDescription" class="description">
                                {{ item.description }}
                            </p>
                            <input type="text"
                                   :name="item.id"
                                   :id="item.id"
                                   v-model="contentData[item.id]"
                                   :disabled="isDisabled(item)"
                            />
                            <span v-if="errors[item.id]" class="field-error">
                                {{ errors[item.id][0] }}
                            </span>
                        </div>
                        <MultiCountry
                            v-if="item.type === 'multiCountry'"
                            :item="item"
                            :old="old"
                            :errors="errors"
                            :isDisabled="isDisabled(item)"
                            :countries="countries"
                        >
                        </MultiCountry>
                        <template v-if="item.type === 'dataProtection'">
                            <ProtectionContact
                                :policy-type="policyType"
                                :item="item"
                                :errors="errors"
                                :old="old">
                            </ProtectionContact>
                        </template>
                    </template>
                </div>
            </fieldset>
        </div>
    </template>
</template>

<script>
import $ from 'jquery';
import {PlusIcon, TrashIcon} from "@heroicons/vue/24/outline"
import RadioOptions from "@/js/components/user_policy_form/FormFields/RadioOptions.vue";
import TextArea from "@/js/components/user_policy_form/FormFields/TextArea.vue";
import ProtectionContact from "@/js/components/user_policy_form/CustomFields/ProtectionContact.vue";
import MultiCountry from "@/js/components/user_policy_form/FormFields/MultiCountry.vue";
import {useCreateFormStore} from "@/stores/createFormStore.js";

export default {
    components: {
        MultiCountry,
        ProtectionContact,
        TrashIcon,
        PlusIcon,
        RadioOptions,
        TextArea
    },
    props: {
        free: {
            type: Boolean,
            default: false,
        },
        token: {
            type: String,
            default: null
        },
        regionalId: {
            type: Number,
            default: null
        },
        step: {
            type: Object,
            default: () => ({}),
        },
        compliancePackId: {
            type: Number,
        },
        content: {
            type: Object,
            default: () => ({}),
        },
        policyType: {
            type: String
        },
        selectedPolicyTypes: {
            type: Array,
            default: () => [],
        },
        countries: {
            type: Array,
            default: () => [],
        },
        errors: {
            type: Object,
            default: () => ({}),
        },
        old: {
            type: Object,
            default: () => ({}),
        },
        useStore: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            radioInputPlaceholders: {},
            contentData: this.content,
            customInputs: {},
        };
    },
    mounted() {
        this.initializeCustomInputsFromContentData();
        this.setInitialGroupVisibility();
        this.setInitialValuesFromOld();
        this.$nextTick(() => {
            $(this.$refs.countrySelect).selectize({
                create: false,
                sortField: 'text',
            });
        });
    },
    computed: {
        isDisabled() {
            return (item) => {
                if (!item.validation || !item.validation.rules) {
                    return false;
                }

                for (const rule of item.validation.rules) {
                    if (rule.type === 'disabled_with') {
                        for (const option of rule.options) {
                            if (this.contentData[option] === true ||
                                (typeof this.contentData[option] === 'string' && this.contentData[option].length > 0) ||
                                (Array.isArray(this.contentData[option]) && this.contentData[option].length > 0) ||
                                (typeof this.contentData[option] === 'number' && !isNaN(this.contentData[option]))) {
                                return true;
                            }
                        }
                    } else if (rule.type === 'disabled_without') {
                        let allUnchecked = true;
                        for (const option of rule.options) {
                            if (this.contentData[option] === true ||
                                (typeof this.contentData[option] === 'string' && this.contentData[option].length > 0) ||
                                (Array.isArray(this.contentData[option]) && this.contentData[option].length > 0) ||
                                (typeof this.contentData[option] === 'number' && !isNaN(this.contentData[option]))) {
                                allUnchecked = false;
                                break;
                            }
                        }
                        if (allUnchecked) {
                            return true;
                        }
                    }
                }
                return false;
            };
        },
    },
    methods: {
        setInitialValuesFromOld() {
            for (const key in this.old) {
                if (this.old.hasOwnProperty(key)) {
                    this.contentData[key] = this.old[key];
                }
            }
        },
        toggleActive(option) {
            if (option.groups.length) {
                option.defaultChecked = !option.defaultChecked;
            }
        },
        toggleGroups(id, event) {
            this.$nextTick(() => {
                const groupsRef = this.$refs[`${id}-groups`];
                if (groupsRef && groupsRef.length > 0) {
                    groupsRef[0].style.display = event.target.checked ? 'block' : 'none';
                }
            });
        },
        isChecked(optionId) {
            const checkbox = document.getElementById(optionId);
            return checkbox ? checkbox.checked : false;
        },
        selectOption(optionId, optionType, $event) {
            if ($event.target.checked) {

                this.contentData[optionId] = true;

                if (optionType === 'addOwn') {
                    this.customInputs[optionId] = [''];
                }
            } else {
                delete this.contentData[optionId];
                if (optionType === 'addOwn') {
                    delete this.customInputs[optionId];
                }
            }
            this.contentData = {...this.contentData};
        },
        isOptionSelected(option) {


            if (this.old.length !== 0 && this.old[option.id]) {
                return true;
            }

            const input = document.getElementById(option.id);
            if (input && input.checked) {
                return true;
            }

            if (this.old && Object.keys(this.old).length !== 0 && !this.old[option.id]) {
                return false;
            }
            if (option.defaultChecked) {
                return true;
            }

            return !!this.contentData[option.id];
        },
        isPrimaryOptionSelected(option) {

            if (this.isOptionSelected(option)) {
                return true;
            }

            if(!option.name){
                return true;
            }

            if (this.old && Object.keys(this.old).length !== 0 && !this.old[option.id]) {
                return false;
            }

            if(this.useStore){
                const formStore = useCreateFormStore();
                const storedPolicies = formStore.getSelectedPolicies(this.token);

                if (this.policyType !== 'privacy' && storedPolicies.includes(this.policyType)) {
                    return true;
                }
            }
        },
        setInitialGroupVisibility() {
            this.$nextTick(() => {
                this.step.options.forEach((option) => {
                    this.toggleGroupVisibilityForOption(option);
                });
            });
        },
        initializeCustomInputsFromContentData() {
            Object.entries(this.contentData).forEach(([optionId, value]) => {
                if (Array.isArray(value)) {
                    this.customInputs[optionId] = value;
                }
            });
            Object.entries(this.old).forEach(([optionId, value]) => {
                if (Array.isArray(value)) {
                    this.customInputs[optionId] = value;
                }
            });
        },
        toggleGroupVisibilityForOption(option) {
            if (this.isOptionSelected(option)) {
                const groupsRef = this.$refs[`${option.id}-groups`];
                if (groupsRef && groupsRef.length > 0) {
                    groupsRef[0].style.display = 'block';
                }
            }

            if (option.groups && option.groups.length > 0) {
                option.groups.forEach(group => {
                    group.items.forEach(item => {
                        if (item.type === 'checkbox' || item.type === 'addOwn') {
                            this.toggleGroupVisibilityForOption(item);
                        }
                    });
                });
            }
        },
        addCustomInput(optionId, index) {
            if (!this.customInputs[optionId]) {
                this.customInputs[optionId] = [];
            }
            this.customInputs[optionId].splice(index + 1, 0, '');
        },
        deleteCustomInput(optionId, index) {
            if (!this.customInputs[optionId]) return;

            this.customInputs[optionId].splice(index, 1);

            if (this.customInputs[optionId].length === 0) {
                delete this.customInputs[optionId];
                delete this.contentData[optionId];
            } else {
                this.contentData[optionId] = [...this.customInputs[optionId]];
            }

            this.customInputs = {...this.customInputs};
            this.contentData = {...this.contentData};
        },
        updateCustomInput(optionId, index, value) {
            if (this.customInputs[optionId]) {
                this.customInputs[optionId][index] = value;
                this.contentData[optionId] = [...this.customInputs[optionId]];

                this.customInputs = {...this.customInputs};
            }
        },
        isDisabledOnForm(option) {
            if (this.free && this.regionalId) {
                return true;
            }
            if (this.free && option.disabledOnForm) {
                return true;
            }
            if (this.free && this.policyType != 'privacy') {
                return true;
            }
            return false;
        }
    },
};
</script>
