<template>
    <fieldset class="privacy-policies custom-radio">
        <legend>Select the primary use for your policies:</legend>
        <div class="custom-radio__inner">
            <div v-for="(type, key) in compliancePackTypes" :key="key" class="field">
                <input
                    type="radio"
                    :name="name"
                    :value="type.value"
                    :id="`policy_type__${type.value}`"
                    class="required"
                    :checked="isChecked(type.value, key)"
                    @change="handlePolicyTypeChange"
                />
                <label :for="`policy_type__${type.value}`" v-html="typeContent(type)" />
            </div>
        </div>
        <p class="description">All Products include unlimited edits, policy hosting and future updates.</p>
    </fieldset>
</template>

<script>

export default {
    props: {
        compliancePackTypes: {
            type: Array,
            required: true
        },
        compliancePack: {
            type: Object,
            default: null
        },
        name: {
            type: String,
            required: true
        }
    },
    mounted() {
        this.updateSidebarContent();
    },
    methods: {
        typeContent(type) {
            let content = '';
            if (type.icon) {
                content = type.icon;
            }
            content += `<span>${type.label}</span>`;
            return content;
        },
        isChecked(value, key) {
            if (this.compliancePack && this.compliancePack.compliance_pack_type.slug === value) {
                return true;
            }
            // Check the first option by default if none are checked
            return key === 0 && !this.compliancePack;
        },
        handlePolicyTypeChange(event) {
            this.updateSidebarContent(event.target.value);
        },
        updateSidebarContent(policyType = null) {
            if (!policyType) {
                const selectedInput = document.querySelector('input[name="policy_type"]:checked');
                policyType = selectedInput ? selectedInput.value : null;
            }
            if (policyType) {
                document.querySelectorAll('.block--policy').forEach(block => block.classList.add('hide'));
                const activeBlock = document.querySelector(`.block--policy--${policyType}`);
                if (activeBlock) {
                    activeBlock.classList.remove('hide');
                }
            }
        }
    },
    watch: {
        compliancePack(newVal) {
            this.updateSidebarContent(newVal.compliance_pack_type.slug);
        }
    }
};
</script>
