<template>
    <div class="form-section">
        <div class="fields">
            <h2 class="title">Privacy Contact</h2>
            <p class="description">Please supply the contact details that users may forward their privacy enquiries to:</p>

            <div class="form-group contact-widget-wrap">
                <div class="fields">
                    <div class="field">
                        <label for="privacy-contact[name]">Privacy Contact Name*</label>
                        <input type="text" name="privacy-contact[name]"
                               v-model="privacyContactName"
                               class=""
                               data-error-input="privacy-contact.name">
                        <span v-if="errors['privacy-contact.name']" class="field-error error">
                            {{ errors['privacy-contact.name'][0] }}
                        </span>
                    </div>
                </div>

                <fieldset>
                    <legend>Contact method for your Privacy Contact</legend>

                    <div class="fields" data-grid="third">
                        <!-- Online Form / Page -->
                        <div class="field field--radio field--third">
                            <input type="radio" name="privacy-contact[contact-type]"
                                   value="online-form-page"
                                   id="privacy-contact__type--online-form-page" v-model="selectedContactType">
                            <label for="privacy-contact__type--online-form-page">
                                <span>Online form / page</span>
                            </label>
                        </div>

                        <!-- Email / Phone -->
                        <div class="field field--radio field--third">
                            <input type="radio" name="privacy-contact[contact-type]"
                                   value="email-phone"
                                   id="privacy-contact__type--email-phone" v-model="selectedContactType">
                            <label for="privacy-contact__type--email-phone">
                                <span>Email / Phone</span>
                            </label>
                        </div>

                        <!-- Other -->
                        <div class="field field--radio field--third">
                            <input type="radio" name="privacy-contact[contact-type]"
                                   value="other"
                                   id="privacy-contact__type--other" v-model="selectedContactType">
                            <label for="privacy-contact__type--other">
                                <span>Other</span>
                            </label>
                        </div>
                    </div>

                    <!-- Online Form / Page input field -->
                    <div class="field field--full contact-widget-input privacy-contact__type--online-form-page"
                         :class="{ hide: selectedContactType !== 'online-form-page' }">
                        <label for="privacy-contact[contact-online-form-page]">Add URL</label>
                        <input type="text"
                               name="privacy-contact[contact-online-form-page]"
                               v-model="contactOnlineFormPage"
                        />
                        <span v-if="errors['privacy-contact.contact-online-form-page']"
                              class="field-error error">
                            {{ errors['privacy-contact.contact-online-form-page'][0] }}
                        </span>
                    </div>

                    <!-- Email / Phone input field -->
                    <div class="field field--full contact-widget-input privacy-contact__type--email-phone"
                         :class="{ hide: selectedContactType !== 'email-phone' }">
                        <label for="privacy-contact[contact-email-phone]">Add Email/Phone</label>
                        <input type="text" name="privacy-contact[contact-email-phone]"
                               v-model="contactEmailPhone"
                               placeholder="Add Email/Phone"
                        />
                        <span v-if="errors['privacy-contact.contact-email-phone']" class="field-error error">
                            {{ errors['privacy-contact.contact-email-phone'][0] }}
                        </span>
                    </div>

                    <!-- Other input field -->
                    <div class="field field--full contact-widget-input privacy-contact__type--other"
                         :class="{ hide: selectedContactType !== 'other' }">
                        <label for="privacy-contact[contact-other]">Add Other</label>
                        <input type="text" name="privacy-contact[contact-other]"
                               v-model="contactOther"
                               placeholder="Add Other"
                        />
                        <span v-if="errors['privacy-contact.contact-other']" class="field-error error">
                            {{ errors['privacy-contact.contact-other'][0] }}
                        </span>
                    </div>
                </fieldset>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        errors: {
            type: Object,
            default: () => ({}),
        },
        old: {
            type: Object,
            default: () => ({}),
        }
    },
    data() {
        return {
            selectedContactType: 'online-form-page', // default to online form
            privacyContactName: this.old['privacy-contact'] ? this.old['privacy-contact']['name'] ?? '' : '',
            contactOnlineFormPage: this.old['privacy-contact'] ? this.old['privacy-contact']['contact-online-form-page'] ?? 'https://' : 'https://',
            contactEmailPhone: this.old['privacy-contact'] ? this.old['privacy-contact']['contact-email-phone'] ?? '' : '',
            contactOther: this.old['privacy-contact'] ? this.old['privacy-contact']['contact-other'] ?? '' : ''
        };
    },
    mounted() {
        if (this.old && this.old['privacy-contact'] && this.old['privacy-contact']['contact-type']) {
            this.selectedContactType = this.old['privacy-contact']['contact-type'];
        }
    },
};
</script>
