<template>
    <div class="flex flex-col flex-1">
        <div class="flex flex-1 items-stretch">
            <textarea ref="textArea" v-model="list.value" placeholder="List Item Details (Optional)" class="textarea-class flex-1 border-2 border-t-white h-[42px]"></textarea>
            <select v-model="list.listType" class="text-black self-start border-white border-l-gray-800">
                <option v-for="{ value, text } in listTypeOptions" :key="value" :value="value">
                    {{ text }}
                </option>
            </select>
            <select v-if="list.listType === 'ol'" v-model="list.orderType" class="select-monospace text-black self-start border-white border-l-gray-800">
                <option v-for="{ value, text } in listOrderOptions" :key="value" :value="value">
                    {{ text }}
                </option>
            </select>

            <div v-tooltip="'Add List Item'"
                 :class="['text-white bg-gray-700 flex items-center justify-center p-2 hover:bg-gray-800 border-t-2 border-b-2 border-r-2']">
                <button @click.prevent="addItem">
                    <ListBulletIcon class="h-5 w-5 text-green-500"/>
                </button>
            </div>
            <div v-if="!show" v-tooltip="'Show All'"
                 :class="['text-white bg-gray-700 flex items-center justify-center p-2 hover:bg-gray-800 border-t-2 border-b-2 border-r-2']">
                <button @click.prevent="showAll">
                    <ArrowDownTrayIcon class="h-5 w-5 text-white-500"/>
                </button>
            </div>
            <div v-if="show" v-tooltip="'Hide All'"
                 :class="['text-white bg-gray-700 flex items-center justify-center p-2 hover:bg-gray-800 border-t-2 border-b-2 border-r-2']">
                <button @click.prevent="hideAll">
                    <ArrowUpTrayIcon class="h-5 w-5 text-white-500"/>
                </button>
            </div>
        </div>
        <div v-if="show" class="flex flex-col flex-1 items-stretch ml-6 pt-2">
            <draggable v-model="list.items" class="drag-area" :itemKey="(item) => item.id" handle=".drag-handle">
                <template #item="{ element, index }">
                    <div :key="element.id" class="flex flex-1 flex-col mb-2"
                         :class="[element.showSettings ? 'border-4 border-warning-400' : '']">
                        <div class="flex flex-1 items-stretch">
                            <ListItemInput :text="element.text" :add-own="element.addOwn" :id="element.id"/>
                            <!-- Validation Settings button -->
                            <div v-tooltip="'Validation Settings'"
                                 class="text-white bg-gray-700 flex items-center justify-center p-2 hover:bg-gray-800 border-t-2 border-b-2 border-r-2">
                                <button @click.prevent="toggleSettings(element.id)">
                                    <Cog6ToothIcon class="h-5 w-5 text-white-500"/>
                                </button>
                            </div>
                            <!-- Delete Step button -->
                            <div v-tooltip="'Delete Step'"
                                 class="text-white bg-gray-700 flex items-center justify-center p-2 hover:bg-gray-800 border-t-2 border-b-2 border-r-2">
                                <button @click.prevent="removeContent(element.id)">
                                    <TrashIcon class="h-5 w-5 text-red-500"/>
                                </button>
                            </div>
                        </div>
                        <!-- Content settings component -->
                        <div v-if="element.showSettings">
                            <list-item-content-settings
                                :settings="element.settings"
                                :options="policyData"
                                :privacy-options="privacyData"
                                :policy-types="policyTypes"
                                :is-app="isApp"
                            />
                        </div>
                    </div>
                </template>
            </draggable>
        </div>
    </div>
</template>

<script>
import {
    ArrowDownTrayIcon,
    ArrowUpTrayIcon,
    Cog6ToothIcon,
    ListBulletIcon,
    TrashIcon
} from "@heroicons/vue/24/outline/index.js";
import ListItemInput from "./ListItemInput.vue";
import ListItemContentSettings from "./ListItemContentSettings.vue";
import draggable from "vuedraggable";
export default {
    components: {
        ListItemInput,
        ListItemContentSettings,
        draggable,
        TrashIcon,
        Cog6ToothIcon,
        ArrowDownTrayIcon,
        ArrowUpTrayIcon,
        ListBulletIcon
    },
    props: {
        list: Object,
        policyData: {
            type: Array,
            default: () => [],
        },
        privacyData: {
            type: Array,
            default: () => [],
        },
        policyTypes: {
            type: Object,
            default: () => ({}),
        },
        isApp:{
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            show: true,
            listTypeOptions: [
                {value: 'ul', text: 'UL'},
                {value: 'ol', text: 'OL'},
            ],
            listOrderOptions: [
                {value: '1', text: '1'},
                {value: 'A', text: 'A'},
                {value: 'a', text: 'a'},
                {value: 'I', text: 'Ⅰ'},
                {value: 'i', text: 'i'},
            ],
        }
    },
    methods: {
        showAll() {
            this.show = true;
        },
        hideAll() {
            this.show = false;
        },
        generateUniqueId() {
            return Date.now().toString(36) + "-" + Math.random().toString(36).substring(2);
        },
        addItem() {
            this.list.items.push({
                showSettings: false,
                id: this.generateUniqueId(),
                settings: {
                    visibility: 'show',
                    evaluate: 'ifHas',
                    options: null,
                    else: '',
                    elseListItem: {
                        value: '',
                    },
                    orListItems: []
                },
                text: {
                    value: '',
                    addOwn: false
                },
                addOwn: false
            });
        },
        toggleSettings(id) {
            const index = this.list.items.findIndex(item => item.id === id);
            if (index !== -1) {
                this.list.items[index].showSettings = !this.list.items[index].showSettings;
            }
        },
        removeContent(id) {
            const index = this.list.items.findIndex(item => item.id === id);

            if (index !== -1) {
                this.list.items.splice(index, 1);
            }
        }
    }
}
</script>
<style>
@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@400;600&display=swap');

.select-monospace {
    font-family: 'Source Code Pro', monospace !important;
}
</style>
