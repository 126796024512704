import { defineStore } from 'pinia';

export const useCreateFormStore = defineStore('form', {
    state: () => ({
        selectedPolicies: {},
    }),
    actions: {
        getSelectedPolicies(token) {
            return this.selectedPolicies[token] || [];
        },
        setSelectedPolicies(token, policies) {
            this.selectedPolicies[token] = policies;
        },
        updateSelectedPolicies(token, event, policy) {
            if (!token) return;

            const selectedPolicies = this.getSelectedPolicies(token);
            if (event.target.checked) {
                if (!selectedPolicies.includes(policy)) {
                    selectedPolicies.push(policy);
                }
            } else {
                const index = selectedPolicies.indexOf(policy);
                if (index > -1) {
                    selectedPolicies.splice(index, 1);
                }
            }
            this.setSelectedPolicies(token, selectedPolicies);
        },
    },
    persist: true,
});
