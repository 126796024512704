<template>
    <div class="checkbox-option-wrapper p-2 bg-gray-800 mt-4 relative">
        <div class="flex items-stretch mt-2">
            <div class="rounded-l-lg bg-blue-100 flex items-center justify-center p-2 drag-handle">
                <ChevronUpDownIcon class="w-6 h-6 text-black"/>
            </div>
            <input v-model="step.name"
                   class="text-black checkbox-option-name border-t-2 border-b-2 flex-1 relative border-white border-l-gray-800"
                   :placeholder="'Step ' + (stepIndex + 1) + ' Name'">
            <div v-tooltip="'Validation'"
                 :class="[
                         'flex items-center justify-center p-2 hover:bg-gray-800 border-t-2 border-b-2 border-r-2',
                         step.type === 'radio' ? 'border-l-2' : '',
                         step.validationVisible ? 'bg-green-600' : 'bg-white'
                         ]">
                <button @click.prevent="toggleStepValidation(stepIndex)">
                    <CheckIcon :class="['h-5 w-5', step.validationVisible ? 'text-white' : 'text-green-500']"/>
                </button>
            </div>
            <div v-tooltip="'Default State'"
                 class="text-white bg-gray-700 flex items-center justify-center p-2 hover:bg-gray-800 border-t-2 border-b-2 border-r-2">
                <div :class="['flex items-center justify-center h-5 w-5']">
                    <input
                        type="checkbox"
                        checked
                        v-if="step.defaultChecked"
                        @change="toggleStepDefaultState(stepIndex)"
                        class="form-checkbox h-4 w-4 text-gray-600 border-gray-300 rounded"
                    />
                    <input
                        type="checkbox"
                        v-if="!step.defaultChecked"
                        @change="toggleStepDefaultState(stepIndex)"
                        class="form-checkbox h-4 w-4 text-gray-600 border-gray-300 rounded"
                    />
                </div>
            </div>
            <div v-tooltip="(step.descriptionVisible ? 'Hide' : 'Add') + ' description text'"
                 class="text-white bg-gray-700 flex items-center justify-center p-2 hover:bg-gray-800 border-t-2 border-b-2 border-r-2">
                <button @click.prevent="toggleStepDescription(stepIndex)">
                    <ChatBubbleLeftIcon class="h-5 w-5"/>
                </button>
            </div>
            <div v-tooltip="(step.contentVisible ? 'Hide' : 'Show') + ' content'"
                 v-if="step.description || step.options.length"
                 class="text-white bg-gray-700 flex items-center justify-center p-2 hover:bg-gray-800 border-t-2 border-b-2 border-r-2">
                <button @click.prevent="toggleStepContentVisibility(stepIndex)">
                    <ArrowUpTrayIcon v-if="step.contentVisible" class="h-5 w-5 text-white"/>
                    <ArrowDownTrayIcon v-else class="h-5 w-5 text-white"/>
                </button>
            </div>
            <div v-tooltip="'Delete Step'"
                 class="text-white bg-gray-700 flex items-center justify-center p-2 hover:bg-gray-800 border-t-2 border-b-2 border-r-2">
                <button @click.prevent="removeStep(stepIndex)">
                    <TrashIcon class="h-5 w-5 text-red-500"/>
                </button>
            </div>
        </div>

        <transition name="expand" @before-enter="beforeEnter" @enter="enter" @leave="leave">
            <div>
                <div v-if="step.validationVisible" class="flex flex-1 flex-row text-black">
                    <select v-model="step.validation.rule"
                            class="w-auto pr-6 p-2 text-black bg-white border-2 border-gray-300">
                        <option v-for="{ value, text } in rules" :key="value" :value="value">
                            {{ text }}
                        </option>
                    </select>
                    <Multiselect
                        v-if="step.validation.rule !== 'required'"
                        v-model="step.validation.options"
                        mode="tags"
                        :options="availableOptions"
                        :close-on-select="false"
                        :searchable="true"
                        :search-filter="customSearchFilter"
                        label="name"
                        track-by="value"
                        class="custom-multiselect"
                    />
                </div>
                <div v-if="step.contentVisible">
                    <div v-if="step.descriptionVisible" class="flex">
                        <input v-model="step.description"
                               class="text-black p-2 border-2 flex-1 border-white border-t-gray-800"
                               :placeholder="getDescriptionPlaceholder()">
                    </div>
                    <draggable v-model="step.options" item-key="id" @start="drag=true" @end="drag=false"
                               handle=".drag-handle">
                        <template #item="{ element, index }">
                            <OptionComponent
                                :key="element.id"
                                :option="element"
                                :stepIndex="stepIndex"
                                :optionIndex="index"
                                :content="content"
                                :languages="languages"
                                :free="free"
                            />
                        </template>
                    </draggable>
                    <div class="flex">
                        <button v-if="!step.isRegional" @click.prevent="addOption(stepIndex)"
                                class="mt-4 flex items-center justify-center space-x-2 bg-green-800 hover:bg-green-600 text-white font-semibold py-1 px-4 border border-green-800 rounded shadow hover:shadow-md transition-colors duration-150">
                            <span>Add Option</span>
                        </button>
                        <button v-if="!step.options.length" @click.prevent="step.isRegional = !step.isRegional"
                                class="ml-2 mt-4 flex items-center justify-center space-x-2 bg-warning-400 hover:bg-warning-500 text-white font-semibold py-1 px-4 border border-warning-400 rounded shadow hover:shadow-md transition-colors duration-150">
                            <span> {{step.isRegional ? 'Remove Regional' : 'Add Regional'}}</span>
                        </button>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>

import draggable from 'vuedraggable';
import OptionComponent from "./OptionComponent.vue";
import {
    ArchiveBoxXMarkIcon,
    ArrowDownTrayIcon,
    ArrowRightCircleIcon,
    ArrowUpTrayIcon,
    ChatBubbleLeftIcon,
    CheckCircleIcon,
    ChevronUpDownIcon,
    TrashIcon,
    ListBulletIcon,
    PlusCircleIcon
} from "@heroicons/vue/24/outline"
import {CheckIcon} from "@heroicons/vue/24/outline/index.js";

export default {
    components: {
        CheckIcon,
        ArchiveBoxXMarkIcon,
        ArrowDownTrayIcon,
        ArrowRightCircleIcon,
        ArrowUpTrayIcon,
        ChatBubbleLeftIcon,
        CheckCircleIcon,
        ChevronUpDownIcon,
        draggable,
        ListBulletIcon,
        OptionComponent,
        PlusCircleIcon,
        TrashIcon,
    },
    props: {
        free: {
            type: Boolean,
            required: true,
        },
        step: {
            type: Object,
            required: true,
        },
        stepIndex: {
            type: Number,
            required: true
        },
        content: {
            type: Array,
            default: () => [],
        },
        languages: {
            type: Array,
            default: () => [],
        }
    },
    data() {
        return {
            drag: false,
            rules: [
                {value: null, text: 'None'},
                {value: 'required_with', text: 'Required With'},
                {value: 'required_with_any', text: 'Required With Any'},
                {value: 'required_without', text: 'Required Without Any'},
                {value: 'required_without_all', text: 'Required Without All'},
            ],
        };
    },
    inject: [
        'toggleStepDefaultState',
        'toggleStepValidation',
        'toggleStepDescription',
        'toggleStepContentVisibility',
        'removeStep',
        'addOption',
    ],
    methods: {
        getDescriptionPlaceholder() {
            return `Description for ${this.step.name || `Step ${this.stepIndex + 1}`}`;
        },
        beforeEnter(el) {
            el.style.height = '0';
            el.style.opacity = '0';
        },
        enter(el, done) {
            this.$nextTick(() => {
                el.style.transition = 'height 0.5s ease';
                el.style.height = `${el.scrollHeight}px`;
                el.style.opacity = '1';
                el.addEventListener('transitionend', () => {
                    el.style.height = null; // Remove fixed height after transition to allow for responsive changes
                    done();
                });
            });
        },
        leave(el, done) {
            el.style.height = `${el.scrollHeight}px`;
            el.style.opacity = '1';
            // Force reflow, making sure the transition starts from the current height
            getComputedStyle(el).height;
            setTimeout(() => {
                el.style.transition = 'height 0.5s ease';
                el.style.height = '0';
                el.style.opacity = '0';
                el.addEventListener('transitionend', done);
            });
        },
        customSearchFilter(option, query, select$) {
            return option.name.toLowerCase().includes(query.toLowerCase());
        },
    },
    computed: {
        availableOptions() {
            let optionsMap = {};
            this.content.forEach(step => {
                step.options.forEach(option => {
                    if (option.name && !optionsMap[option.id]) {
                        optionsMap[option.id] = {
                            value: option.id,
                            name: option.name
                        };
                    }
                    option.groups.forEach(group => {
                        group.items.forEach(item => {
                            if (item.name && !optionsMap[item.id]) {
                                optionsMap[item.id] = {
                                    value: item.id,
                                    name: option.name + (group.name ? ' - ' + group.name + ' - ' : ' - ') + item.name
                                };
                            }
                            item.groups.forEach(itemGroup => {
                                itemGroup.items.forEach(itemGroupItem => {
                                    if (itemGroupItem.name && !optionsMap[itemGroupItem.id]) {
                                        optionsMap[itemGroupItem.id] = {
                                            value: itemGroupItem.id,
                                            name: option.name + (group.name ? ' - ' + group.name + ' - ' : ' - ') + item.name + ' - ' + itemGroupItem.name
                                        };
                                    }
                                });
                            })
                            item.radioOptions.forEach(radioOption => {
                                if (radioOption.name && !optionsMap[radioOption.id]) {
                                    optionsMap[radioOption.id] = {
                                        value: radioOption.id,
                                        name: option.name + ' - ' + group.name + ' - ' + radioOption.name
                                    };
                                }
                            })
                        });
                    });
                });
            });
            return Object.values(optionsMap);
        }
    },
};
</script>
