<template>
    <fieldset class="additional-policies custom-checkbox">
        <legend>Select your policies & inclusions:</legend>
        <div class="custom-checkbox__inner">
            <div v-for="policy in policyOptions" :key="policy.value" class="field field--checkbox">
                <input
                    type="checkbox"
                    :id="'additional-policy-' + policy.value"
                    name="options[]"
                    :value="policy.value"
                    :checked="isPolicySelected(policy.value)"
                    @change="updateSelectedPolicies($event, policy.value)"
                    :disabled="policy.value === 'privacy'"
                />
                <label :for="'additional-policy-' + policy.value">{{ policy.label }}</label>
            </div>
        </div>
    </fieldset>
</template>

<script>
import {useCreateFormStore} from "@/stores/createFormStore.js";

export default {
    props: {
        policyOptions: {
            type: Array,
            required: true,
        },
        compliancePack: {
            type: Object,
            required: false,
        }
    },
    methods: {
        isPolicySelected(policyValue) {
            if (policyValue === 'privacy') {
                return true;
            }
            if (this.compliancePack && this.compliancePack.token) {
                const store = useCreateFormStore();
                const selectedPolicies = store.getSelectedPolicies(this.compliancePack.token);
                return selectedPolicies.includes(policyValue);
            }
            return false;
        },
        updateSelectedPolicies(event, policy) {
            if (this.compliancePack && this.compliancePack.token) {
                const store = useCreateFormStore();
                store.updateSelectedPolicies(this.compliancePack.token, event, policy);
            }
        }
    }
};
</script>

<style scoped>
/* Add your styles here */
</style>
