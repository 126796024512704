<template>
    <div class="flex flex-1 items-stretch">
        <textarea ref="textArea" v-model="text.value" placeholder="Text Content" class="textarea-class flex-1 border-2 border-t-white h-[42px]"></textarea>
        <div v-if="!show" v-tooltip="'Expand'"
             :class="['text-white bg-gray-700 flex items-center justify-center p-2 hover:bg-gray-800 border-t-2 border-b-2 border-r-2 max-h-[42px]']">
            <button @click.prevent="showAll">
                <ArrowDownTrayIcon class="h-5 w-5 text-white-500"/>
            </button>
        </div>
        <div v-if="show"  v-tooltip="'Hide'"
             :class="['text-white bg-gray-700 flex items-center justify-center p-2 hover:bg-gray-800 border-t-2 border-b-2 border-r-2 max-h-[42px]']">
            <button @click.prevent="hideAll">
                <ArrowUpTrayIcon class="h-5 w-5 text-white-500"/>
            </button>
        </div>
    </div>
</template>

<script>
import {
    ArrowDownTrayIcon,
    ArrowUpTrayIcon
} from "@heroicons/vue/24/outline/index.js";

export default {
    components: {
        ArrowDownTrayIcon,
        ArrowUpTrayIcon
    },
    props: {
        text: Object,
    },
    data() {
        return {
            show: false,
            defaultHeight: '42px',
        }
    },
    methods: {
        showAll() {
            this.show = true;
            const textarea = this.$refs.textArea;
            textarea.style.height = 'auto';
            textarea.style.height = textarea.scrollHeight + 'px';
        },
        hideAll() {
            this.show = false;
            const textarea = this.$refs.textArea;
            textarea.style.height = this.defaultHeight;
        }
    }
}
</script>
