<template>
    <div class="flex-1 ml-4 mt-4">
        <div class="flex items-stretch mt-2">
            <div class="rounded-l-lg bg-blue-100 flex items-center justify-center p-2 drag-handle">
                <ChevronUpDownIcon class="w-6 h-6 text-black"/>
            </div>
            <input v-model="option.name" class="flex-1 text-black border-white border-l-gray-800"
                   :placeholder="'Option ' + (optionIndex + 1) + ' Name (Optional). Creates checkbox.'">
            <div v-tooltip="(option.disabledOnForm ? 'Disable' : 'Enable') + ' on form'"
                 v-if="free"
                 class="text-white bg-gray-700 flex items-center justify-center p-2 hover:bg-gray-800 border-t-2 border-b-2 border-r-2">
                <button @click.prevent="toggleOptionDisabledOnForm(stepIndex, optionIndex)">
                    <LockClosedIcon v-if="option.disabledOnForm" class="h-5 w-5 text-red-500"/>
                    <LockOpenIcon v-else class="h-5 w-5 text-green-500"/>
                </button>
            </div>
            <div v-tooltip="'Default State'"
                 class="text-white bg-gray-700 flex items-center justify-center p-2 hover:bg-gray-800 border-t-2 border-b-2 border-r-2">
                <div :class="['flex items-center justify-center h-5 w-5']">
                    <input
                        type="checkbox"
                        checked
                        v-if="option.defaultChecked"
                        @change="toggleOptionDefaultState(stepIndex, optionIndex)"
                        class="form-checkbox h-4 w-4 text-gray-600 border-gray-300 rounded"
                    />
                    <input
                        type="checkbox"
                        v-if="!option.defaultChecked"
                        @change="toggleOptionDefaultState(stepIndex, optionIndex)"
                        class="form-checkbox h-4 w-4 text-gray-600 border-gray-300 rounded"
                    />
                </div>
            </div>
            <div v-tooltip="(option.descriptionVisible ? 'Remove' : 'Add') + ' option description'"
                 class="text-white bg-gray-700 flex items-center justify-center p-2 hover:bg-gray-800 border-t-2 border-b-2 border-r-2">
                <button @click.prevent="toggleOptionDescription(stepIndex, optionIndex)">
                    <ChatBubbleLeftIcon class="h-5 w-5 text-white"/>
                </button>
            </div>
            <div v-tooltip="'Add option group'"
                 class="text-white bg-gray-700 flex items-center justify-center p-2 hover:bg-gray-800 border-t-2 border-b-2 border-r-2">
                <button @click.prevent="addGroup(stepIndex, optionIndex)">
                    <ListBulletIcon class="h-5 w-5 text-green-500"/>
                </button>
            </div>
            <div v-tooltip="(option.contentVisible ? 'Hide' : 'Show') + ' option content'"
                 v-if="(option.description && option.description.length && option.descriptionVisible) || option.groups.length"
                 class="text-white bg-gray-700 flex items-center justify-center p-2 hover:bg-gray-800 border-t-2 border-b-2 border-r-2">
                <button @click.prevent="toggleOptionContentVisibility(stepIndex, optionIndex)">
                    <ArrowUpTrayIcon v-if="option.contentVisible" class="h-5 w-5 text-white"/>
                    <ArrowDownTrayIcon v-else class="h-5 w-5 text-white"/>
                </button>
            </div>
            <div v-tooltip="'Delete option'"
                 class="text-white bg-gray-700 flex items-center justify-center p-2 hover:bg-gray-800 border-t-2 border-b-2 border-r-2">
                <button @click.prevent="removeOption(stepIndex, optionIndex)">
                    <TrashIcon class="h-5 w-5 text-red-500"/>
                </button>
            </div>
        </div>
        <transition name="expand" @before-enter="beforeEnter" @enter="enter" @leave="leave">
            <div v-if="option.contentVisible">
                <div v-if="option.descriptionVisible">
                    <input v-model="option.description" class="text-black p-2 border-2 w-full border-white border-t-gray-800"
                           :placeholder="getDescriptionPlaceholder(option.id)">
                </div>
                <draggable v-model="option.groups" item-key="id" @start="drag=true" @end="drag=false" handle=".drag-handle">
                    <template #item="{ element, index }">
                        <GroupComponent
                            :key="element.id"
                            :group="element"
                            :stepIndex="stepIndex"
                            :optionIndex="optionIndex"
                            :groupIndex="index"
                            :content="content"
                            :languages="languages"
                        />
                    </template>
                </draggable>
            </div>
        </transition>
    </div>
</template>

<script>
import GroupComponent from './GroupComponent.vue';
import draggable from 'vuedraggable';

import {
    ArchiveBoxXMarkIcon,
    ArrowDownTrayIcon,
    ArrowRightCircleIcon,
    ArrowUpTrayIcon,
    ChatBubbleLeftIcon,
    CheckCircleIcon,
    ChevronUpDownIcon,
    TrashIcon,
    ListBulletIcon,
    PlusCircleIcon,
    LockOpenIcon,
    LockClosedIcon
} from "@heroicons/vue/24/outline"

export default {
    components: {
        ArchiveBoxXMarkIcon,
        ArrowDownTrayIcon,
        ArrowRightCircleIcon,
        ArrowUpTrayIcon,
        ChatBubbleLeftIcon,
        CheckCircleIcon,
        ChevronUpDownIcon,
        draggable,
        TrashIcon,
        ListBulletIcon,
        PlusCircleIcon,
        GroupComponent,
        LockOpenIcon,
        LockClosedIcon
    },
    props: {
        free: {
            type: Boolean,
            required: true,
        },
        option: {
            type: Object,
            required: true,
        },
        stepIndex: {
            type: Number
        },
        optionIndex: {
            type: Number,
            required: true
        },
        content: {
            type: Array,
            default: () => [],
        },
        languages: {
            type: Array,
            default: () => [],
        }
    },
    data() {
        return {
            drag: false,
        };
    },
    inject: [
        'toggleOptionDescription',
        'toggleOptionDefaultState',
        'toggleOptionDisabledOnForm',
        'toggleOptionContentVisibility',
        'addGroup',
        'removeOption',
    ],
    methods: {
        beforeEnter(el) {
            el.style.height = '0';
            el.style.opacity = '0';
        },
        enter(el, done) {
            this.$nextTick(() => {
                el.style.transition = 'height 0.5s ease';
                el.style.height = `${el.scrollHeight}px`;
                el.style.opacity = '1';
                el.addEventListener('transitionend', () => {
                    el.style.height = null;
                    done();
                });
            });
        },
        leave(el, done) {
            el.style.height = `${el.scrollHeight}px`;
            el.style.opacity = '1';
            getComputedStyle(el).height;
            setTimeout(() => {
                el.style.transition = 'height 0.5s ease';
                el.style.height = '0';
                el.style.opacity = '0';
                el.addEventListener('transitionend', done);
            });
        },
        getDescriptionPlaceholder() {
            return `Description for ${this.option.name || `Option ${this.optionIndex + 1}`}`;
        },
    }
};
</script>
