<template>
    <div class="fields" :data-grid="width">
        <!--    Custom Radio Options    -->
        <div v-for="(option, index) in radioOptions"
             :key="option.id"
             :class="['field field--radio field--' + width]">
            <input type="radio"
                   :checked="isChecked(option.id)"
                   :id="option.id"
                   :value="option.id"
                   :name="itemId"
                   :disabled="isDisabled"
                   @change="updateRadioSelection(itemId, option.id)">
            <label :for="option.id">{{ option.name }}</label>
        </div>

        <!--    Custom Radio Inputs    -->
        <div v-if="allOptionsHaveDescription(radioOptions)"
             class="field field--full">
            <input
                type="text"
                :placeholder="activeRadioOption.description"
                :id="'description-' + activeRadioOption.id"
                :name="activeRadioOption.id"
                :value="localContentData[activeRadioOption.id] || ''"
                :disabled="isDisabled"
                @input="updateDescription(activeRadioOption.id, $event.target.value)">
            <span v-if="errors[activeRadioOption.id]" class="field-error">
                {{ errors[activeRadioOption.id][0] }}
            </span>
        </div>
        <span v-if="errors[itemId]" class="field-error">
            {{ errors[itemId][0] }}
        </span>
    </div>
</template>

<script>
export default {
    props: {
        itemId: {
            type: String,
            required: true
        },
        radioOptions: {
            type: Array,
            required: true
        },
        width: {
            type: String,
            default: 'full'
        },
        old: {
            type: Object,
            default: () => ({})
        },
        errors: {
            type: Object,
            default: () => ({})
        },
        isDisabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            localContentData: {...this.old}
        };
    },
    computed: {
        activeRadioOption() {
            const selectedValue = this.localContentData[this.itemId]?.value;
            return this.radioOptions.find(option => option.id === selectedValue) || {};
        }
    },
    methods: {
        updateRadioSelection(itemId, selectedValue) {
            this.localContentData = {
                ...this.localContentData,
                [itemId]: {value: selectedValue}
            };
            this.clearError(itemId);
        },
        updateDescription(optionId, description) {
            this.localContentData = {
                ...this.localContentData,
                [optionId]: description
            };
            this.clearError(optionId);
        },
        clearError(field) {
            this.errors[field] = null;
        },
        allOptionsHaveDescription(radioOptions) {
            return radioOptions.every(option => option.description);
        },
        isChecked(optionId) {
            return this.localContentData[this.itemId]?.value === optionId;
        },
        ensureFirstOptionSelected() {
            if (!this.radioOptions.some(option => this.isChecked(option.id))) {
                this.updateRadioSelection(this.itemId, this.radioOptions[0].id);
            }
        }
    },
    mounted() {
        // Set the initially selected radio option and make its input field visible
        if (this.old[this.itemId]) {
            this.localContentData[this.itemId] = {value: this.old[this.itemId]};
        }
        this.ensureFirstOptionSelected();

    }
};
</script>
