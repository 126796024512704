<template>
    <div class="flex flex-1 items-stretch">
        <input class="flex-1 border-1 border-t-white border-b-white" type="text" v-model="header.value"
               placeholder="Header Content"/>
        <select v-if="type !== 'privacy_contact'" v-model="header.type"
                class="border-1 border-t-white border-b-white">
            <option value="h2">H2</option>
            <option value="h3">H3</option>
            <option value="h4">H4</option>
            <option value="h5">H5</option>
            <!-- Add more header types as needed -->
        </select>
    </div>
</template>

<script>
import {
    TrashIcon,
} from "@heroicons/vue/24/outline"

export default {
    components: {
        TrashIcon
    },
    props: {
        header: Object,
        type: String
    }
}
</script>
