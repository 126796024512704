<template>
    <div class="fixed inset-0 flex items-center justify-center z-50 m-20">
        <div class="fixed inset-0 bg-gray-900 opacity-50"></div>
        <div
            class="relative bg-white text-black rounded-lg shadow-xl z-50 overflow-y-auto max-h-90vh min-w-[80%] min-h-[80%]">
            <!-- Modal content goes here -->
            <div class="relative overflow-y-auto p-4 pb-12" style="max-height: 90vh;">
                <div v-for="section in content"
                     :class="['p-2', section.showSettings ? 'bg-yellow-50 border-2 rounded-md mb-2' : '']"
                     :key="section.id"
                >
                    <p v-if="section.showSettings" class="font-bold text-red-500">
                        {{ ucfirst(section.settings.visibility) }}
                        {{ evaluateText(section.settings.evaluate) }}
                        <span
                            v-for="(optionId, index) in section.settings.options"
                            :key="optionId"
                            class="mr-1"
                        >
                            [{{ optionsMap[optionId] }}]
                        </span>
                    </p>
                    <div v-for="sectionContent in section.content"
                         :class="['p-2', sectionContent.showSettings ? 'bg-yellow-50 border-2 rounded-md mb-2' : '']"
                         :key="sectionContent.id">

                        <div v-if="sectionContent.type === 'header'">
                            <component
                                :is="`${sectionContent.header.type}`"
                                :class="headingClass(sectionContent.header.type)"
                            >
                                {{ sectionContent.header.value }}
                            </component>
                        </div>
                        <p v-else-if="sectionContent.type === 'text'"
                           v-html="formatTextWithLineBreaks(sectionContent.text.value)"/>
                        <div v-else-if="sectionContent.type === 'list'">
                            <p v-if="sectionContent.list.value"
                               v-html="formatTextWithLineBreaks(sectionContent.list.value)"/>
                            <component
                                :is="sectionContent.list.listType ?? 'ul'"
                                :class="sectionContent.list.listType === 'ol' ?
                                listOrderClasses(sectionContent.list.orderType) :
                                'list-[disc] list-inside'"
                            >
                                <li v-for="(item, index) in sectionContent.list.items" :key="index"
                                    :class="[item.showSettings ? 'bg-yellow-50 border-2 rounded-md' : '']">
                                    {{ item.text.value }}
                                    <span v-if="item.showSettings" class="font-bold text-red-500">
                                        {{ ucfirst(item.settings.visibility) }}
                                        {{ evaluateText(item.settings.evaluate) }}
                                        <span
                                            v-for="(optionId, index) in item.settings.options"
                                            :key="optionId"
                                            class="mr-1"
                                        >
                                            [{{ optionsMap[optionId] }}]
                                        </span>
                                    </span>
                                </li>
                            </component>
                        </div>
                        <div v-else>
                            {{ sectionContent }}
                        </div>
                        <p v-if="sectionContent.showSettings" class="font-bold text-red-500">
                            {{ ucfirst(sectionContent.settings.visibility) }}
                            {{ evaluateText(sectionContent.settings.evaluate) }}
                            <span
                                v-for="(optionId, index) in sectionContent.settings.options"
                                :key="optionId"
                                class="mr-1"
                            >
                                [{{ optionsMap[optionId] }}]
                            </span>
                        </p>
                        <div v-if="sectionContent.showSettings">
                            <div v-for="orListItem in sectionContent.settings.orListItems" class="mt-3">
                                <p v-html="formatTextWithLineBreaks(orListItem.orText)"/>
                                <span v-if="orListItem.options" class="font-bold text-red-500">
                                        {{ ucfirst(orListItem.visibility) }}
                                        {{ evaluateText(orListItem.evaluate) }}
                                        <span
                                            v-for="(optionId, index) in orListItem.options"
                                            :key="optionId"
                                            class="mr-1"
                                        >
                                            [{{ optionsMap[optionId] }}]
                                        </span>
                                    </span>
                            </div>
                            <p v-if="sectionContent.settings.elseText && sectionContent.settings.elseText.value" class="mt-3">
                                <span class="font-bold text-red-500">Else </span>
                                {{ sectionContent.settings.elseText.value }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="bg-white absolute right-4 bottom-4 mt-4 flex justify-end ">
                <!-- Close button -->
                <button class="px-4 py-2 bg-gray-300 text-gray-700 rounded-md hover:bg-gray-400"
                        @click.prevent="$emit('close')">Close
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        content: {
            type: Array,
            default: () => [],
        },
        policyData: Array,
    },
    data() {
        return {
            policyOptions: this.flattenPolicyData()
        }
    },
    computed: {
        optionsMap() {
            return this.policyOptions.reduce((acc, {id, name}) => {
                acc[id] = name;
                return acc;
            }, {});
        }
    },
    methods: {
        flattenPolicyData() {
            const flatData = [];
            const extractData = (items) => {
                items.forEach((item) => {
                    if (item.id && item.name) {
                        flatData.push({id: item.id, name: item.name});
                    }
                    if (item.options) {
                        extractData(item.options);
                    }
                    if (item.groups) {
                        item.groups.forEach((group) => {
                            if (group.items) {
                                extractData(group.items);
                            }
                        });
                    }
                    if (item.type === 'radio') {
                        extractData(item.radioOptions)
                    }
                });
            };

            extractData(this.policyData);
            return flatData;
        },
        headingClass(type) {
            switch (type) {
                case 'h2':
                    return 'text-2xl font-bold';
                case 'h3':
                    return 'text-xl font-semibold';
                case 'h4':
                    return 'text-lg font-medium';
                // Add more cases as needed
                default:
                    return '';
            }
        },
        formatTextWithLineBreaks(text) {
            // Replace newlines with HTML <br> tags
            // also ensure anything in the string that starts with [ and ends with ] is bolded
            return text.replace(/\n/g, '<br>')
                .replace(/\[([^\]]+)\]/g, '<strong>[$1]</strong>');
        },
        listOrderClasses(listOrder) {
            switch (listOrder) {
                case 'A':
                    return 'list-[upper-alpha] list-inside';
                case 'a':
                    return 'list-[lower-alpha] list-inside';
                case 'I':
                    return 'list-[upper-roman] list-inside';
                case 'i':
                    return 'list-[lower-roman] list-inside';
                case '1':
                    return 'list-[decimal] list-inside';
            }
        },
        evaluateText(text) {
            switch (text) {
                case 'ifHas':
                    return 'If Has';
                case 'ifNot':
                    return 'If Not';
                case 'ifAny':
                    return 'If Any';
                case 'ifAll':
                    return 'If All';
                default:
                    return ''; // or any default text you want to show
            }
        },
        ucfirst(string) {
            if (!string) return '';
            return string.charAt(0).toUpperCase() + string.slice(1);
        },
    },
};
</script>
<style>
.max-h-90vh {
    max-height: 90vh;
}
</style>
