<template>
    <label>{{ item.name }}</label>
    <p v-if="item.showDescription" class="description">
        {{ item.description }}
    </p>
    <select :id="item.id"
            :name="item.id + '[]'"
            ref="countrySelect"
            multiple
            class="selectized"
            :disabled="isDisabled"
    >
        <option v-for="country in countries" :key="country.id" :value="country.id">
            {{ country.name }}
        </option>

    </select>
    <span v-if="errors[item.id]" class="field-error">
        Please select a country
    </span>
</template>

<script>
import $ from "jquery";
import 'selectize';

export default {
    props: {
        item: {
            type: Object,
            required: true
        },
        errors: {
            type: Object,
            default: () => ({}),
        },
        old: {
            type: Object,
            default: () => ({}),
        },
        countries: {
            type: Array,
            default: () => []
        },
        isDisabled: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        setInitialValue() {
            if (this.old[this.item.id]) {
                this.$refs.countrySelect.selectize.setValue(this.old[this.item.id]);
            }
        }
    },
    mounted() {
        this.$nextTick(() => {
            $(this.$refs.countrySelect).selectize({
                create: false,
                sortField: 'text',
            });
            this.setInitialValue();
        });
    },
};
</script>
