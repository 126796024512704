<template>
    <div class="flex flex-col items-stretch w-full">
        <div class="flex flex-1 items-stretch">
            <textarea ref="introTextArea" v-model="element.intro.value" placeholder="Intro Content"
                      class="textarea-class flex-1 border-2 border-t-white h-[42px]"></textarea>
            <div v-if="!element.intro.show" v-tooltip="'Expand'"
                 :class="['text-white bg-gray-700 flex items-center justify-center p-2 hover:bg-gray-800 border-t-2 border-b-2 border-r-2 max-h-[42px]']">
                <button @click.prevent="showAll('intro')">
                    <ArrowDownTrayIcon class="h-5 w-5 text-white-500"/>
                </button>
            </div>
            <div v-if="element.intro.show" v-tooltip="'Hide'"
                 :class="['text-white bg-gray-700 flex items-center justify-center p-2 hover:bg-gray-800 border-t-2 border-b-2 border-r-2 max-h-[42px]']">
                <button @click.prevent="hideAll('intro')">
                    <ArrowUpTrayIcon class="h-5 w-5 text-white-500"/>
                </button>
            </div>
        </div>
        <div v-for="item in element.evaluatedItems">
            <div :class="['mt-2', item.showSettings ? 'border-4 border-warning-400' : '']">
                <div class="flex flex-1 items-stretch">
                    <textarea :id="'item-' + item.id" v-model="item.text.value" placeholder="Item Text"
                          class="textarea-class flex-1 border-2 border-t-white h-[42px]"></textarea>
                    <div v-if="!item.show" v-tooltip="'Expand'"
                         :class="['text-white bg-gray-700 flex items-center justify-center p-2 hover:bg-gray-800 border-t-2 border-b-2 border-r-2 max-h-[42px]']">
                        <button @click.prevent="toggleShowItem(item.id)">
                            <ArrowDownTrayIcon class="h-5 w-5 text-white-500"/>
                        </button>
                    </div>
                    <div v-if="item.show" v-tooltip="'Hide'"
                         :class="['text-white bg-gray-700 flex items-center justify-center p-2 hover:bg-gray-800 border-t-2 border-b-2 border-r-2 max-h-[42px]']">
                        <button @click.prevent="toggleShowItem(item.id)">
                            <ArrowUpTrayIcon class="h-5 w-5 text-white-500"/>
                        </button>
                    </div>
                    <div v-tooltip="'Validation Settings'"
                         :class="['text-white flex items-center justify-center p-2 hover:bg-gray-800 border-t-2 border-b-2 border-r-2 max-h-[42px]', item.showSettings ? 'bg-gray-800' : 'bg-gray-700' ]">
                        <button @click.prevent="toggleSettings(item.id)">
                            <Cog6ToothIcon class="h-5 w-5 text-white-500"/>
                        </button>
                    </div>
                    <div v-if="element.evaluatedItems.length > 1" v-tooltip="'Delete Step'"
                         :class="['text-white bg-gray-700 flex items-center justify-center p-2 hover:bg-gray-800 border-t-2 border-b-2 border-r-2 max-h-[42px]']">
                        <button @click.prevent="removeContent(item.id)">
                            <TrashIcon class="h-5 w-5 text-red-500"/>
                        </button>
                    </div>
                    <div v-tooltip="'Add Item'"
                         class="text-white flex items-center justify-center p-2 bg-gray-700 hover:bg-gray-800 border-t-2 border-b-2 border-r-2 max-h-[42px]'">
                        <button @click.prevent="addItem(item.id)">
                            <PlusIcon class="h-5 w-5 text-green-500"/>
                        </button>
                    </div>
                </div>
                <div v-if="item.showSettings">
                    <!-- Content settings component -->
                    <content-settings
                        :settings="item.settings"
                        :options="policyData"
                        :privacy-options="privacyData"
                        :type="item.type"
                        :policy-type="policyType"
                        :policy-types="policyTypes"
                        :else-enabled="false"
                        :is-app="isApp"
                    />
                </div>
            </div>

        </div>
        <div class="flex flex-1 items-stretch mt-2">
            <textarea ref="outroTextArea" v-model="element.outro.value" placeholder="Outro Content"
                      class="textarea-class flex-1 border-2 border-t-white h-[42px]"></textarea>
            <div v-if="!element.outro.show" v-tooltip="'Expand'"
                 :class="['text-white bg-gray-700 flex items-center justify-center p-2 hover:bg-gray-800 border-t-2 border-b-2 border-r-2 max-h-[42px]']">
                <button @click.prevent="showAll('outro')">
                    <ArrowDownTrayIcon class="h-5 w-5 text-white-500"/>
                </button>
            </div>
            <div v-if="element.outro.show" v-tooltip="'Hide'"
                 :class="['text-white bg-gray-700 flex items-center justify-center p-2 hover:bg-gray-800 border-t-2 border-b-2 border-r-2 max-h-[42px]']">
                <button @click.prevent="hideAll('outro')">
                    <ArrowUpTrayIcon class="h-5 w-5 text-white-500"/>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import {
    ArrowDownTrayIcon,
    ArrowUpTrayIcon, Cog6ToothIcon, PlusIcon, TrashIcon
} from "@heroicons/vue/24/outline/index.js";
import ContentSettings from "./ContentSettings.vue";

export default {
    components: {
        ContentSettings,
        PlusIcon,
        TrashIcon, Cog6ToothIcon,
        ArrowDownTrayIcon,
        ArrowUpTrayIcon
    },
    props: {
        element: Object,
        generateUniqueId: Function,
        policyData: {
            type: Array,
            default: () => [],
        },
        privacyData: {
            type: Array,
            default: () => [],
        },
        policyType: {
            type: String,
            default: '',
        },
        policyTypes: {
            type: Object,
            default: () => ({}),
        },
        isApp: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            show: false,
            defaultHeight: '42px',
        }
    },
    methods: {
        showAll(type) {
            if(type === 'intro'){
                this.element.intro.show = true;
                const textarea = this.$refs.introTextArea;
                textarea.style.height = 'auto';
                textarea.style.height = textarea.scrollHeight + 'px';
            } else if(type === 'outro'){
                this.element.outro.show = true;
                const textarea = this.$refs.outroTextArea;
                textarea.style.height = 'auto';
                textarea.style.height = textarea.scrollHeight + 'px';
            }
        },
        hideAll(type) {
            if(type === 'intro'){
                this.element.intro.show = false;
                const textarea = this.$refs.introTextArea;
                textarea.style.height = this.defaultHeight;
            } else if(type === 'outro'){
                this.element.outro.show = false;
                const textarea = this.$refs.outroTextArea;
                textarea.style.height = this.defaultHeight;
            }
        },
        toggleShowItem(id) {
            const item = this.element.evaluatedItems.find(item => item.id === id);
            if (item) {
                item.show = !item.show;
                const textarea = document.getElementById('item-' + id);
                if (textarea) {
                    if(item.show){
                        textarea.style.height = 'auto';
                        textarea.style.height = textarea.scrollHeight + 'px';
                    } else {
                        textarea.style.height = this.defaultHeight;
                    }
                }
            }
        },
        toggleSettings(id) {
            const item = this.element.evaluatedItems.find(item => item.id === id);
            if (item) {
                item.showSettings = !item.showSettings;
            }
        },
        removeContent(id) {
            const item = this.element.evaluatedItems.find(item => item.id === id);
            if (item) {
                this.element.evaluatedItems.splice(this.element.evaluatedItems.indexOf(item), 1);
            }
        },
        addItem(id) {
            const item = this.element.evaluatedItems.find(item => item.id === id);
            if (item) {
                const newItem = {
                    id: this.generateUniqueId(),
                    type: 'text',
                    showSettings: false,
                    settings: {
                        visibility: '',
                        evaluate: '',
                        options: null
                    },
                    text: {
                        value: ''
                    }
                }
                this.element.evaluatedItems.splice(this.element.evaluatedItems.indexOf(item) + 1, 0, newItem);
            }
        }
    }
}
</script>
