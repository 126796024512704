<template>
    <template v-if="!multipart">
        <template v-for="step in visibleSteps" :key="step.id">
            <div class="fields" v-if="regionalId || (step.isVisible && isPolicyTypeInStore) || visibleForEdit">
                <h2 v-if="!this.index && title" class="title">{{ title }}</h2>
                <p v-if="!this.index && description" class="description">{{ description }}</p>
                <form-step
                    :free="free"
                    :token="token"
                    :regional-id="regionalId"
                    :step="step"
                    :compliance-pack-id="compliancePackId"
                    :content="content"
                    :policy-type="policyType"
                    :selected-policy-types="selectedPolicyTypes"
                    :countries="countries"
                    :errors="errors"
                    :old="old"
                    :use-store="useStore"
                ></form-step>
            </div>
        </template>
    </template>
</template>

<script>
import FormStep from "./FormStep.vue";
import {useCreateFormStore} from "@/stores/createFormStore.js";

export default {
    components: {
        FormStep,
    },
    props: {
        token: {
            type: String,
            required: true,
        },
        index: {
            type: Number,
            required: false,
        },
        title: {
            type: String,
            default: '',
        },
        description: {
            type: String,
            default: '',
        },
        regionalId: {
            type: Number,
            default: null
        },
        form: {
            type: Array,
            default: () => [],
        },
        compliancePackId: {
            type: Number,
        },
        content: {
            type: Object,
            default: () => ({}),
        },
        multipart: {
            type: Boolean,
            default: false
        },
        policyType: {
            type: String
        },
        selectedPolicyTypes: {
            type: Array,
            default: () => [],
        },
        countries: {
            type: Array,
            default: () => [],
        },
        errors: {
            type: Object,
            default: () => ({}),
        },
        old: {
            type: Object,
            default: () => ({}),
        },
        type: {
            type: String,
            default: null,
        },
        visibleForEdit: {
            type: Boolean,
            default: false,
        },
        useStore: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            radioInputPlaceholders: {},
            steps: [],
            step: 0,
            contentData: this.content,
            customInputs: {},
            free: this.type === 'website-free'
        };
    },
    mounted() {
        const formStore = useCreateFormStore();
        const storedPolicies = formStore.getSelectedPolicies(this.token);

        if (storedPolicies.length === 0) {
            formStore.setSelectedPolicies(this.token, this.selectedPolicyTypes);
        }
        this.steps = this.deepCopy(this.form);
    },
    methods: {
        deepCopy(obj) {
            return JSON.parse(JSON.stringify(obj));
        },
        isStepVisible(step) {

            if (!step.validationVisible) return true;

            const {rule, options} = step.validation;

            switch (rule) {
                case 'required_with':
                    // All optionIds in step.validation.options must be checked
                    return options.every(optionId => this.isChecked(optionId));

                case 'required_with_any':
                    // At least one optionId in step.validation.options must be checked
                    return options.some(optionId => this.isChecked(optionId));

                case 'required_without':
                    // All optionIds in step.validation.options must be unchecked
                    return options.every(optionId => !this.isChecked(optionId));

                case 'required_without_all':
                    // At least one optionId in step.validation.options must be unchecked
                    return options.some(optionId => !this.isChecked(optionId));

                default:
                    return true; // Default to visible if rule is undefined or not matched
            }
        },
        nextStep() {
            // Find the next visible step's index
            const nextIndex = this.visibleSteps.findIndex((step, index) => index > this.step && step.isVisible);
            // Update the current step if a next visible step is found
            if (nextIndex !== -1) {
                this.step = nextIndex;
            }
        },
        prevStep() {
            // Find the previous visible step's index in reverse order
            const prevIndex = [...this.visibleSteps].slice(0, this.step).reverse().findIndex((step, index) => step.isVisible);
            // If a previous visible step is found, calculate its index from the start of the array
            if (prevIndex !== -1) {
                this.step = this.step - prevIndex - 1;
            }
        }
    },
    computed: {
        visibleSteps() {
            return this.steps.map(step => {
                const isVisible = this.isStepVisible(step);
                return {...step, isVisible}; // Spread operator to clone step and add isVisible
            });
        },
        hasNextStep() {
            return this.visibleSteps.findIndex((step, index) => index > this.step && step.isVisible) !== -1;
        },
        hasPrevStep() {
            const beforeCurrent = this.visibleSteps.slice(0, this.step);
            return beforeCurrent.reverse().findIndex(step => step.isVisible) !== -1;
        },
        isPolicyTypeInStore() {
            if(this.useStore) {
                const formStore = useCreateFormStore();
                const storedPolicies = formStore.getSelectedPolicies(this.token);
                return this.policyType === "privacy" || storedPolicies.includes(this.policyType);
            }
            return false;
        }
    },

};
</script>

