<template>
    <div>
        <div class="flex flex-col">
            <div class="flex items-center">
                <p>Shortcodes</p>
                <button @click.prevent="shortcodesVisible = !shortcodesVisible"
                        class="ml-2 text-sm flex items-center justify-center space-x-1 bg-green-800 hover:bg-green-600 text-white font-semibold py-1 px-4 border border-green-800 rounded shadow hover:shadow-md transition-colors duration-150">
                    {{ shortcodesVisible ? 'Hide' : 'Show' }}
                </button>
            </div>
            <div class="flex flex-wrap" v-if="shortcodesVisible">
                <p>Default Shortcodes</p>
                <div class="flex flex-wrap">
                    <div v-for="(code, index) in standard_shortcodes" :key="index">
                        <button @click.prevent="copyCode(code, index, 'standard')"
                                :class="['mr-1 mt-1 flex items-center justify-center space-x-2 text-gray-700 font-semibold py-1 px-2 border shadow hover:shadow-md transition-colors duration-150',
                            copiedStandardIndex === index ? 'bg-gray-600 text-gray-300' : 'bg-gray-300 hover:bg-gray-400 border-gray-400']">
                            {{ code }}
                        </button>
                    </div>
                </div>
                <p v-if="custom_shortcodes" class="mt-2">Custom Shortcodes</p>
                <div v-if="custom_shortcodes" class="flex flex-wrap">
                    <div v-for="(code, index) in custom_shortcodes" :key="index">
                        <button @click.prevent="copyCode(code, index, 'custom')"
                                :class="['mr-1 mt-1 flex items-center justify-center space-x-2 text-gray-700 font-semibold py-1 px-2 border shadow hover:shadow-md transition-colors duration-150',
                            copiedCustomIndex === index ? 'bg-gray-600 text-gray-300' : 'bg-gray-300 hover:bg-gray-400 border-gray-400']">
                            {{ code }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <draggable v-model="policyContent" class="drag-area" handle=".drag-handle" item-key="id" @start="drag=true" @end="onDragEnd">
            <template #item="{ element, index }">
                <div :key="element.id" :class="['mt-2']">
                    <div class="flex flex-row justify-end items-stretch">
                        <div class="flex flex-row pl-2 bg-blue-100 rounded-t items-center justify-center drag-handle">
                            <ChevronUpDownIcon class="w-6 h-6 text-black"/>
                            <div class="mr-2 text-black">Section</div>
                        </div>
                        <input v-if="element.type === 'privacy_contact'"
                               :ref="'nameInput_' + index"
                               class="flex-grow-1 border-1 border-t-white border-b-white text-gray-800" type="text"
                               v-model="element.name"
                               placeholder="Name"
                               value="Privacy Contact Snippet"
                               disabled/>
                        <input v-else
                               :ref="'nameInput_' + index"
                               class="flex-grow-1 border-1 border-t-white border-b-white text-gray-800" type="text"
                               v-model="element.name"
                               placeholder="Name"
                               @input="adjustInputSize(index)"/>
                        <div v-if="element.type !== 'privacy_contact'" v-tooltip="'Validation Settings'"
                             @click.prevent="toggleSettings(element.id)"
                             :class="['text-white flex items-center justify-center p-2 hover:bg-gray-800 border-t-2 border-b-2 border-r-2 ', element.showSettings ? 'bg-gray-800' : 'bg-gray-700' ]">
                            <button>
                                <Cog6ToothIcon class="h-5 w-5 text-white-500"/>
                            </button>
                        </div>
                        <div v-if="!element.showContent" v-tooltip="'Expand'"
                             :class="['text-white bg-gray-700 flex items-center justify-center p-2 hover:bg-gray-800 border-t-2 border-b-2 border-r-2 max-h-[42px]']">
                            <button @click.prevent="toggleVisibility(element.id)">
                                <ArrowDownTrayIcon class="h-5 w-5 text-white-500"/>
                            </button>
                        </div>
                        <div v-if="element.showContent" v-tooltip="'Hide'"
                             :class="['text-white bg-gray-700 flex items-center justify-center p-2 hover:bg-gray-800 border-t-2 border-b-2 border-r-2 max-h-[42px]']">
                            <button @click.prevent="toggleVisibility(element.id)">
                                <ArrowUpTrayIcon class="h-5 w-5 text-white-500"/>
                            </button>
                        </div>
                        <div v-tooltip="'Delete Section'"
                             :class="['text-white bg-gray-700 flex items-center justify-center p-2 hover:bg-gray-800 border-t-2 border-b-2 border-r-2']">
                            <button @click.prevent="removeContent(element.id)">
                                <TrashIcon class="h-5 w-5 text-red-500"/>
                            </button>
                        </div>
                    </div>
                    <div v-if="element.showContent"
                         :class="[element.showSettings ? 'border-4 border-warning-400' : '']">
                        <div class='flex flex-row items-stretch text-gray-800 border-2 p-2'>
                            <PolicySection
                                :index="index"
                                :type="element.type"
                                :content="element.content"
                                :policy-data="policyData"
                                :privacy-data="privacyData"
                                :policy-type="policyType"
                                :policy-types="policyTypes"
                                :is-regional="element.isRegional"
                                :has-regional="hasRegional"
                                :is-data-protection="element.isDataProtection"
                                :has-data-protection="hasDataProtection"
                                :is-app="isApp"
                                @toggle-regional="toggleRegional(index)"
                                @toggle-data-protection="toggleDataProtection(index)"
                                @update:content="updateSectionContent(index, $event)"
                            />
                        </div>
                        <div>
                            <div v-if="element.showSettings">
                                <content-settings :settings="element.settings"
                                                 :options="policyData"
                                                 :privacy-options="privacyData"
                                                 :type="element.type"
                                                 :else-enabled="false"
                                                 :policy-type="policyType"
                                                 :policy-types="policyTypes"
                                                 :is-app="isApp"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </draggable>

        <div class="flex flex-row items-start">
            <button @click.prevent="addSection"
                    class="mr-1 mt-4 flex items-center justify-center space-x-2 bg-green-800 hover:bg-green-600 text-white font-semibold py-1 px-4 border border-green-800 rounded shadow hover:shadow-md transition-colors duration-150">
                Add Section
            </button>
            <button v-if="isRegional && !policyContent.some(item => item.type === 'privacy_contact')" @click.prevent="addPrivacyContactSnippet"
                    class="mr-1 mt-4 flex items-center justify-center space-x-2 bg-green-800 hover:bg-green-600 text-white font-semibold py-1 px-4 border border-green-800 rounded shadow hover:shadow-md transition-colors duration-150">
                Add Privacy Contact Snippet
            </button>
            <button @click.prevent="preview = !preview"
                    class="ml-2 mt-4 flex items-center justify-center space-x-2 bg-blue-800 hover:bg-blue-600 text-white font-semibold py-1 px-4 border border-blue-800 rounded shadow hover:shadow-md transition-colors duration-150">
                <span>Preview Content {{policyType}}</span>
            </button>
        </div>
        <ContentPreview
            v-if="preview"
            :content="content"
            :policy-data="policyData"
            @close="preview = !preview">
        </ContentPreview>
    </div>
</template>

<script>

import ContentSettings from "./ContentSettings.vue";
import {
    TrashIcon,
    Cog6ToothIcon,
    ChevronUpDownIcon,
    ArrowDownTrayIcon,
    ArrowUpTrayIcon
} from "@heroicons/vue/24/outline"

import draggable from "vuedraggable";
import PolicySection from "./PolicySection.vue";
import ContentPreview from "./ContentPreview.vue";

export default {
    components: {
        ContentPreview,
        ArrowDownTrayIcon,
        ArrowUpTrayIcon,
        PolicySection,
        draggable,
        ChevronUpDownIcon,
        TrashIcon,
        Cog6ToothIcon,
        ContentSettings
    },
    props: {
        content: {
            type: Array,
            default: () => [],
        },
        policyData: {
            type: Array,
            default: () => [],
        },
        privacyData: {
            type: Array,
            default: () => [],
        },
        policyType: {
            type: String,
            default: '',
        },
        policyTypes: {
            type: Object,
            default: () => ({}),
        },
        isRegional: {
            type: Boolean,
            default: false,
        },
        isApp: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            preview: false,
            policyContent: this.content,
            standard_shortcodes: [
                '[submission_name]',
                '[submission_possessive_name]',
                '[submission_url]',
                '[submission_country]',
                '[submission_effective_date]',
                '[submission_updated_date]',
                '[submission_personal_info_example]',
                '[submission_privacy_contact]',
                '[submission_app_or_website]',
                '[strong][/strong]'
            ],
            custom_shortcodes: [],
            copiedStandardIndex: null,
            copiedCustomIndex: null,
            shortcodesVisible: false,
            drag: false,
        }
    },
    computed: {
        hasRegional() {
            return this.policyContent.some(item => item.isRegional === true);
        },
        hasDataProtection() {
            return this.policyContent.some(item => item.isDataProtection === true);
        }
    },
    methods: {
        updateSectionContent(index, newContent) {
            this.policyContent[index].content = newContent;
            this.$emit('update:content', this.policyContent);
            this.handlePolicyContentUpdate(this.policyContent);
        },
        handlePolicyContentUpdate(newVal) {
            const input = document.getElementById('policy_content');
            if (input) {
                input.value = JSON.stringify(newVal);
                input.dispatchEvent(new Event('input'));
            }
        },
        onDragEnd() {
            this.drag = false;
        },
        generateUniqueId() {
            return Date.now().toString(36) + "-" + Math.random().toString(36).substring(2);
        },
        removeContent(id) {
            const index = this.policyContent.findIndex(item => item.id === id);
            if (index !== -1) {
                this.policyContent.splice(index, 1);
            }
        },
        addPrivacyContactSnippet() {
            const exists = this.policyContent.some(item => item.type === 'privacy_contact');
            if (!exists) {
                this.policyContent.push({
                    id: this.generateUniqueId(),
                    name: 'Regional Privacy Contact',
                    type: 'privacy_contact',
                    showSettings: false,
                    showContent: true,
                    isRegional: false,
                    isDataProtection: false,
                    settings: {
                        visibility: '',
                        evaluate: '',
                        options: null,
                        elseType: '',
                        showElse: false,
                        elseHeader: {
                            type: 'h2',
                            value: ''
                        },
                        elseText: {
                            value: ''
                        },
                        elseList: {
                            value: '',
                            items: []
                        }
                    },
                    content: []
                });
            }
        },
        addSection() {
            this.policyContent.push({
                id: this.generateUniqueId(),
                name: '',
                type: 'section',
                showSettings: false,
                showContent: true,
                isRegional: false,
                isDataProtection: false,
                settings: {
                    visibility: '',
                    evaluate: '',
                    options: null,
                    elseType: '',
                    showElse: false,
                    elseHeader: {
                        type: 'h2',
                        value: ''
                    },
                    elseText: {
                        value: ''
                    },
                    elseList: {
                        value: '',
                        items: []
                    }
                },
                content: []
            });
        },
        toggleSettings(id) {
            const index = this.policyContent.findIndex(item => item.id === id);
            if (index !== -1) {
                this.policyContent[index].showSettings = !this.policyContent[index].showSettings;
            }
        },
        toggleVisibility(id) {
            const index = this.policyContent.findIndex(item => item.id === id);
            if (index !== -1) {
                this.policyContent[index].showContent = !this.policyContent[index].showContent;
            }
        },
        async copyCode(code, index, type) {
            try {
                await navigator.clipboard.writeText(code);
                if(type === 'standard') {
                    this.copiedStandardIndex = index; // Set the clicked button index
                    setTimeout(() => {
                        this.copiedStandardIndex = null; // Reset after 2 seconds
                    }, 2000);
                }
                if(type === 'custom') {
                    this.copiedCustomIndex = index; // Set the clicked button index
                    setTimeout(() => {
                        this.copiedCustomIndex = null; // Reset after 2 seconds
                    }, 2000);
                }
            } catch (err) {
                console.error('Failed to copy:', err);
                this.copiedIndex = null; // Reset the index in case of an error as well
            }
        },
        adjustInputSize(index) {
            const refName = 'nameInput_' + index;
            const inputElement = this.$refs[refName];
            inputElement.style.width = 'auto';
            inputElement.style.width = inputElement.scrollWidth + 'px';
        },
        toggleRegional(index) {
            const section = this.policyContent[index];
            section.isRegional = !section.isRegional;
            section.name = section.isRegional ? "Regional Policies" : '';
        },
        toggleDataProtection(index) {
            const section = this.policyContent[index];
            section.isDataProtection = !section.isDataProtection;
            section.name = section.isDataProtection ? "Data Protection Contact Details" : '';
        }
    },
    mounted() {
        for (let index = 0; index < this.policyContent.length; index++) {
            const refName = 'nameInput_' + index;
            const inputElement = this.$refs[refName];
            if (inputElement) {
                // Reset the input size to its initial size to properly calculate the scroll width
                inputElement.style.width = 'auto';
                // Set the input size based on its content using scroll width
                inputElement.style.width = inputElement.scrollWidth + 'px';
            }
        }
        // we want to interrogate the policyData for all text, textarea, input etc fields so we can create them as shortcode options
        if(this.isApp){
            this.custom_shortcodes.push('[submission_product_name]');
        }
        this.policyData.forEach(data => {
            data.options.forEach(option => {
                option.groups.forEach(group => {
                    group.items.forEach(item => {
                        if (item.shortcode) {
                            const modifiedShortcode = '[' + item.shortcode.replace(/ /g, '_') + ']';
                            this.custom_shortcodes.push(modifiedShortcode);
                        }
                        if(item.groups) {
                            item.groups.forEach(subGroup => {
                                subGroup.items.forEach(subItem => {
                                    if (subItem.shortcode) {
                                        const modifiedShortcode = '[' + subItem.shortcode.replace(/ /g, '_') + ']';
                                        this.custom_shortcodes.push(modifiedShortcode);
                                    }
                                })
                            })
                        }
                    })
                })
            })
        })
    },
    watch: {
        policyContent: {
            deep: true,
            handler(newVal) {
                this.handlePolicyContentUpdate(newVal);
            }
        }
    }
};
</script>
