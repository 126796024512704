import { createApp } from 'vue';
import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';

import PolicyForm from './components/policy_form/PolicyForm.vue';
import PolicyContent from './components/policy_content/PolicyContent.vue';
import CompliancePackForm from './components/user_policy_form/CompliancePackForm.vue';
import PrivacyContact from './components/user_policy_form/CustomFields/PrivacyContact.vue';
import CompliancePackRadios from './components/user_policy_form/CustomFields/CompliancePackRadios.vue';
import AdditionalPolicies from "@/js/components/user_policy_form/CustomFields/AdditionalPolicies.vue";
import { vTooltip } from 'floating-vue';
import 'floating-vue/dist/style.css';
import Multiselect from '@vueform/multiselect';
import '@vueform/multiselect/themes/default.css';
import 'selectize/dist/css/selectize.css';
import 'selectize/dist/js/selectize.min.js';
import UserDocument from "./components/user_policy_content/UserDocument.vue";

const app = createApp({});

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

app.use(pinia);
app.component('policy-form', PolicyForm);
app.component('policy-content', PolicyContent);
app.component('additional-policies', AdditionalPolicies);
app.component('compliance-pack-form', CompliancePackForm);
app.component('privacy-contact', PrivacyContact);
app.component('compliance-pack-radios', CompliancePackRadios);
app.component('user-document', UserDocument);
app.component('Multiselect', Multiselect);
app.directive('tooltip', vTooltip);

app.mount('#app');
