<!-- ContentItem.vue -->
<template>
    <template v-if="checkVisibility(item)">
        <component v-if="item.type === 'header'"
                   :is="item.header.type"
                   v-html="getContent(sectionIndex, item, false)"/>
        <component v-else-if="item.type === 'text'"
                   :is="'p'"
                   v-html="getContent(sectionIndex, item, true)"/>
        <template v-else-if="item.type === 'list'">
            <component :is="'p'"
                       v-if="item.list.value"
                       v-html="replaceShortcodes(item.list.value)"/>
            <component :is="item.list.listType ?? 'ul'"
                       v-html="listItemContent(sectionIndex, item)"
                       :type="item.list.listType === 'ol' ? item.list.orderType : undefined"/>
        </template>
        <component v-else-if="item.type === 'evaluatedText'"
                   :is="'p'"
                   v-html="getEvaluatedText(sectionIndex, item)"/>

    </template>
</template>

<script>
export default {
    props: {
        index: {
            type: Number,
            required: true
        },
        item: {
            type: Object,
            required: true
        },
        sectionIndex: {
            type: Number,
            required: true
        },
        getContent: {
            type: Function,
            required: true
        },
        listItemContent: {
            type: Function,
            required: true
        },
        checkVisibility: {
            type: Function,
            required: true
        },
        replaceShortcodes: {
            type: Function,
            required: true
        },
        getEvaluatedText: {
            type: Function,
            required: true
        }
    }
};
</script>
