<template>
    <div class="fixed inset-0 flex items-center justify-center z-50 m-20">
        <div class="fixed inset-0 bg-gray-900 opacity-50"></div>
        <div class="flex flex-col bg-white text-black rounded-lg shadow-xl z-50 max-h-90vh min-h-[70vh] min-w-[80vw]">
            <div class="flex-grow p-4 overflow-y-auto">
                <div v-if="!multipart">
                    <div v-for="step in visibleSteps" :key="step.id">
                        <div v-if="step.isVisible" class="p-2 mb-4">
                            <h2 v-if="step.name" class="text-3xl font-bold mb-2">{{ step.name }}</h2>
                            <p v-if="step.descriptionVisible">{{ step.description }}</p>
                            <div class="flex flex-col mb-2" v-for="option in step.options" :key="option.id">
                                <div class="flex items-center mb-1">
                                    <input v-if="option.name" type="checkbox" :id="option.id" :name="option.id"
                                           class="h-6 w-6 mr-2" @change="toggleGroups(option.id, $event)">
                                    <label v-if="option.name" class="text-xl" :for="option.id">{{ option.name }}</label>
                                </div>
                                <p class="ml-8 mb-2" v-if="option.descriptionVisible">{{ option.description }}</p>
                                <div v-if="option.groups.length" :ref="option.id + '-groups'"
                                     :class="['bg-gray-100 p-2 border-gray-400 border-2 rounded-sm', option.name ? 'ml-8' : '']"
                                     :style="option.name ? 'display: none' : ''">
                                    <!-- Hidden by default -->
                                    <div v-for="group in option.groups" :key="group.id">
                                        <h2 v-if="group.name" class="text-lg font-bold mb-2">{{ group.name }}</h2>
                                        <div class="grid grid-cols-2 gap-4">
                                            <div v-for="item in group.items" :key="item.id"
                                                 :class="['flex justify-start flex-col mb-0.5', item.span === 'half' ? 'col-span-1' : 'col-span-2']">
                                                <div class="flex items-center"
                                                     v-if="item.type === 'checkbox' || item.type === 'addOwn'">
                                                    <input type="checkbox" :id="item.id" :name="item.id"
                                                           class="h-6 w-6 mr-2"
                                                           @change="toggleGroups(item.id, $event)">
                                                    <label v-if="item.name" :for="item.id">
                                                        {{ item.type === 'addOwn' ? 'Other (add your own)' : item.name }}
                                                    </label>
                                                </div>
                                                <div class="ml-8" v-if="item.showDescription && item.type !== 'textarea'">
                                                    <p class="text-sm">
                                                        {{ item.description }}
                                                    </p>
                                                </div>
                                                <div class="flex flex-col justify-start"
                                                     v-if="
                                                        item.type === 'text' ||
                                                        item.type === 'phone' ||
                                                        item.type === 'email' ||
                                                        item.type === 'url'
                                                    ">
                                                    <label class="font-bold" :for="item.id">{{ item.name }}</label>
                                                    <input type="text" :name="item.id" :id="item.id"/>
                                                </div>
                                                <div class="flex flex-col justify-start" v-if="item.type === 'integer'">
                                                    <label class="font-bold" :for="item.id">{{ item.name }}</label>
                                                    <input type="number" :name="item.id" :id="item.id"/>
                                                </div>
                                                <div class="flex flex-col justify-start" v-if="item.type === 'textarea'">
                                                    <label class="font-bold" :for="item.id">{{ item.name }}</label>
                                                    <textarea type="text" :name="item.id"
                                                              :id="item.id">{{item.description}}</textarea>
                                                </div>
                                                <div class="flex flex-col" v-if="item.type === 'radio'">
                                                    <div class="flex flex-row justify-start gap-4 ">
                                                        <div v-for="(option, index) in item.radioOptions" :key="option.id"
                                                             class="flex items-center mb-2">
                                                            <input type="radio"
                                                                   class="mr-1"
                                                                   :checked="index === 0"
                                                                   :id="option.id"
                                                                   :value="option.name"
                                                                   :name="'radio['+item.id+']'"
                                                                   @click="setRadioInput(item.id, option.description)">
                                                            <label :for="option.id">
                                                                {{ option.name }}
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <input v-if="allOptionsHaveDescription(item.radioOptions)"
                                                           :ref="'radioInput-'+item.id"
                                                           :id="'radioInput-'+item.id"
                                                           :name="'radioInput-'+item.id"
                                                           :placeholder="radioInputPlaceholders[item.id] || item.radioOptions[0].description">
                                                </div>
                                                <div v-if="item.groups.length" :ref="item.id + '-groups'"
                                                     class="flex-1 ml-8 mt-2"
                                                     style="display: none;">
                                                    <!-- Hidden by default -->
                                                    <div v-for="group in item.groups" :key="group.id">
                                                        <h2 v-if="group.name" class="text-lg font-bold mb-2">
                                                            {{ group.name }}
                                                        </h2>
                                                        <div class="grid grid-cols-2 gap-4">
                                                            <div v-for="item in group.items" :key="item.id"
                                                                 :class="['flex items-center mb-0.5', item.span === 'full' ? 'col-span-2 foo' : 'col-span-1 bar']">
                                                                <div
                                                                    v-if="item.type === 'checkbox' || item.type === 'addOwn'">
                                                                    <input type="checkbox" :id="item.id" :name="item.id"
                                                                           class="h-6 w-6 mr-2">
                                                                    <label v-if="item.name" :for="item.id">
                                                                        {{
                                                                            item.type === 'addOwn' ? 'Other (add your own)' : item.name
                                                                        }}
                                                                    </label>
                                                                </div>
                                                                <div class="flex flex-col justify-start"
                                                                     v-if="item.type === 'integer'">
                                                                    <label class="text-sm font-bold"
                                                                           :for="item.id">{{ item.name }}</label>
                                                                    <input type="number" :name="item.id" :id="item.id"/>
                                                                </div>
                                                                <div class="flex flex-col" v-if="item.type === 'radio'">
                                                                    <div class="flex flex-row justify-start gap-4 ">
                                                                        <div v-for="(option, index) in item.radioOptions"
                                                                             :key="option.id"
                                                                             class="flex items-center mb-2">
                                                                            <input type="radio"
                                                                                   class="mr-1"
                                                                                   :checked="index === 0"
                                                                                   :id="option.id"
                                                                                   :value="option.name"
                                                                                   :name="'radio['+item.id+']'"
                                                                                   @click="setRadioInput(item.id, option.description)">
                                                                            <label :for="option.id">
                                                                                {{ option.name }}
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <input
                                                                        v-if="allOptionsHaveDescription(item.radioOptions)"
                                                                        :ref="'radioInput-'+item.id"
                                                                        :id="'radioInput-'+item.id"
                                                                        :name="'radioInput-'+item.id"
                                                                        :placeholder="radioInputPlaceholders[item.id] || item.radioOptions[0].description">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <div v-if="visibleSteps[step] && visibleSteps[step].isVisible" class="p-2 mb-4">
                        <h2 v-if="visibleSteps[step].name" class="text-3xl font-bold mb-2">{{ visibleSteps[step].name }}</h2>
                        <p v-if="visibleSteps[step].descriptionVisible">{{ visibleSteps[step].description }}</p>
                        <div class="flex flex-col mb-2" v-for="option in visibleSteps[step].options" :key="option.id">
                            <div class="flex items-center mb-1">
                                <input v-if="option.name" type="checkbox" :id="option.id" :name="option.id"
                                       class="h-6 w-6 mr-2" @change="toggleGroups(option.id, $event)">
                                <label v-if="option.name" class="text-xl" :for="option.id">{{ option.name }}</label>
                            </div>
                            <p class="ml-8 mb-2" v-if="option.descriptionVisible">{{ option.description }}</p>
                            <div v-if="option.groups.length" :ref="option.id + '-groups'"
                                 :class="['bg-gray-100 p-2 border-gray-400 border-2 rounded-sm', option.name ? 'ml-8' : '']"
                                 :style="option.name ? 'display: none' : ''">
                                <!-- Hidden by default -->
                                <div v-for="group in option.groups" :key="group.id">
                                    <h2 v-if="group.name" class="text-lg font-bold mb-2">{{ group.name }}</h2>
                                    <div class="grid grid-cols-2 gap-4">
                                        <div v-for="item in group.items" :key="item.id"
                                             :class="['flex justify-start flex-col mb-0.5', item.span === 'half' ? 'col-span-1' : 'col-span-2']">
                                            <div class="flex items-center"
                                                 v-if="item.type === 'checkbox' || item.type === 'addOwn'">
                                                <input type="checkbox" :id="item.id" :name="item.id"
                                                       class="h-6 w-6 mr-2"
                                                       @change="toggleGroups(item.id, $event)">
                                                <label v-if="item.name" :for="item.id">
                                                    {{ item.type === 'addOwn' ? 'Other (add your own)' : item.name }}
                                                </label>
                                            </div>
                                            <div class="ml-8" v-if="item.showDescription && item.type !== 'textarea'">
                                                <p class="text-sm">
                                                    {{ item.description }}
                                                </p>
                                            </div>
                                            <div class="flex flex-col justify-start" v-if="item.type === 'text'">
                                                <label class="font-bold" :for="item.id">{{ item.name }}</label>
                                                <input type="text" :name="item.id" :id="item.id"/>
                                            </div>
                                            <div class="flex flex-col justify-start" v-if="item.type === 'textarea'">
                                                <label class="font-bold" :for="item.id">{{ item.name }}</label>
                                                <textarea type="text" :name="item.id"
                                                          :id="item.id">{{item.description}}</textarea>
                                            </div>
                                            <div class="flex flex-col" v-if="item.type === 'radio'">
                                                <div class="flex flex-row justify-start gap-4 ">
                                                    <div v-for="(option, index) in item.radioOptions" :key="option.id"
                                                         class="flex items-center mb-2">
                                                        <input type="radio"
                                                               class="mr-1"
                                                               :checked="index === 0"
                                                               :id="option.id"
                                                               :value="option.name"
                                                               :name="'radio['+item.id+']'"
                                                               @click="setRadioInput(item.id, option.description)">
                                                        <label :for="option.id">
                                                            {{ option.name }}
                                                        </label>
                                                    </div>
                                                </div>
                                                <input v-if="allOptionsHaveDescription(item.radioOptions)"
                                                       :ref="'radioInput-'+item.id"
                                                       :id="'radioInput-'+item.id"
                                                       :name="'radioInput-'+item.id"
                                                       :placeholder="radioInputPlaceholders[item.id] || item.radioOptions[0].description">
                                            </div>
                                            <div v-if="item.groups.length" :ref="item.id + '-groups'"
                                                 class="flex-1 ml-8 mt-2"
                                                 style="display: none;">
                                                <!-- Hidden by default -->
                                                <div v-for="group in item.groups" :key="group.id">
                                                    <h2 v-if="group.name" class="text-lg font-bold mb-2">
                                                        {{ group.name }}
                                                    </h2>
                                                    <div class="grid grid-cols-2 gap-4">
                                                        <div v-for="item in group.items" :key="item.id"
                                                             :class="['flex items-center mb-0.5', item.span === 'full' ? 'col-span-2 foo' : 'col-span-1 bar']">
                                                            <div
                                                                v-if="item.type === 'checkbox' || item.type === 'addOwn'">
                                                                <input type="checkbox" :id="item.id" :name="item.id"
                                                                       class="h-6 w-6 mr-2">
                                                                <label v-if="item.name" :for="item.id">
                                                                    {{ item.type === 'addOwn' ? 'Other (add your own)' : item.name }}
                                                                </label>
                                                            </div>
                                                            <div class="flex flex-col justify-start"
                                                                 v-if="item.type === 'integer'">
                                                                <label class="text-sm font-bold"
                                                                       :for="item.id">{{ item.name }}</label>
                                                                <input type="number" :name="item.id" :id="item.id"/>
                                                            </div>
                                                            <div class="flex flex-col" v-if="item.type === 'radio'">
                                                                <div class="flex flex-row justify-start gap-4 ">
                                                                    <div v-for="(option, index) in item.radioOptions"
                                                                         :key="option.id"
                                                                         class="flex items-center mb-2">
                                                                        <input type="radio"
                                                                               class="mr-1"
                                                                               :checked="index === 0"
                                                                               :id="option.id"
                                                                               :value="option.name"
                                                                               :name="'radio['+item.id+']'"
                                                                               @click="setRadioInput(item.id, option.description)">
                                                                        <label :for="option.id">
                                                                            {{ option.name }}
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <input
                                                                    v-if="allOptionsHaveDescription(item.radioOptions)"
                                                                    :ref="'radioInput-'+item.id"
                                                                    :id="'radioInput-'+item.id"
                                                                    :name="'radioInput-'+item.id"
                                                                    :placeholder="radioInputPlaceholders[item.id] || item.radioOptions[0].description">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mt-auto flex justify-end bg-gray-100 border-t">
                <button v-if="multipart && hasPrevStep" @click.prevent="prevStep" class="px-4 py-2 bg-blue-200 text-gray-700 rounded-md hover:bg-blue-400">Prev</button>
                <!-- Spacer element -->
                <div class="flex-grow" v-if="multipart && (!hasPrevStep || !hasNextStep)"></div>
                <button v-if="multipart && hasNextStep" @click.prevent="nextStep" class="px-4 py-2 bg-blue-200 text-gray-700 rounded-md hover:bg-blue-400">Next</button>
                <button v-if="multipart && !hasNextStep" @click.prevent="alert('saved')" class="px-4 py-2 bg-blue-700 text-white rounded-md hover:bg-blue-400">Save</button>
                <!-- Optionally, place the Close button outside the condition to always show it, or adjust as needed -->
                <button @click.prevent="$emit('close')" class="px-4 py-2 bg-gray-200 text-gray-700 rounded-md hover:bg-gray-400">Close</button>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    props: {
        form: {
            type: Array,
            default: () => [],
        },
        multipart: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            radioInputPlaceholders: {},
            steps: [],
            step: 0
        };
    },
    mounted() {
        this.steps = this.deepCopy(this.form);
    },
    methods: {
        deepCopy(obj) {
            return JSON.parse(JSON.stringify(obj));
        },
        toggleGroups(id, event) {
            this.$nextTick(() => {
                const groupsRef = this.$refs[`${id}-groups`];
                if (groupsRef && groupsRef.length > 0) {
                    groupsRef[0].style.display = event.target.checked ? 'block' : 'none';
                }
            });
            this.steps.forEach((step, index) => {
                if (step.validation && step.validation.rule === 'required_with') {
                    this.steps[index].isVisible = this.isStepVisible(step);
                }
            });
        },
        setRadioInput(itemId, description) {
            this.radioInputPlaceholders[itemId] = description;
        },
        allOptionsHaveDescription(radioOptions) {
            return radioOptions.every(option => option.description && option.description.trim().length > 0);
        },
        isStepVisible(step) {
            if (!step.validationVisible) return true;

            const { rule, options } = step.validation;

            switch (rule) {
                case 'required_with':
                    // All optionIds in step.validation.options must be checked
                    return options.every(optionId => this.isChecked(optionId));

                case 'required_with_any':
                    // At least one optionId in step.validation.options must be checked
                    return options.some(optionId => this.isChecked(optionId));

                case 'required_without':
                    // All optionIds in step.validation.options must be unchecked
                    return options.every(optionId => !this.isChecked(optionId));

                case 'required_without_all':
                    // At least one optionId in step.validation.options must be unchecked
                    return options.some(optionId => !this.isChecked(optionId));

                default:
                    return true; // Default to visible if rule is undefined or not matched
            }
        },
        isChecked(optionId) {
            const checkbox = document.getElementById(optionId);
            return checkbox ? checkbox.checked : false;
        },
        nextStep() {
            // Find the next visible step's index
            const nextIndex = this.visibleSteps.findIndex((step, index) => index > this.step && step.isVisible);
            // Update the current step if a next visible step is found
            if (nextIndex !== -1) {
                this.step = nextIndex;
            }
        },
        prevStep() {
            // Find the previous visible step's index in reverse order
            const prevIndex = [...this.visibleSteps].slice(0, this.step).reverse().findIndex((step, index) => step.isVisible);
            // If a previous visible step is found, calculate its index from the start of the array
            if (prevIndex !== -1) {
                this.step = this.step - prevIndex - 1;
            }
        },
    },
    watch: {
        form: {
            handler(newForm) {
                this.steps = this.deepCopy(newForm);
            },
            deep: true,
            immediate: true,
        },
    },
    computed: {
        visibleSteps() {
            return this.steps.map(step => {
                const isVisible = this.isStepVisible(step);
                return {...step, isVisible}; // Spread operator to clone step and add isVisible
            });
        },
        hasNextStep() {
            return this.visibleSteps.findIndex((step, index) => index > this.step && step.isVisible) !== -1;
        },
        hasPrevStep() {
            const beforeCurrent = this.visibleSteps.slice(0, this.step);
            return beforeCurrent.reverse().findIndex(step => step.isVisible) !== -1;
        },
    },
};
</script>
<style>
.max-h-90vh {
    max-height: 90vh;
}
</style>
