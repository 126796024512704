<template>
    <div>
        <div class="flex flex-1 flex-col md:flex-row">
            <select v-model="settings.visibility" class="w-auto pr-10 p-2 text-black bg-white border-2 border-gray-300">
                <option v-for="{ value, text } in visibilityOptions" :key="value" :value="value">
                    {{ text }}
                </option>
            </select>
            <select v-model="settings.evaluate" class="w-auto pr-10 p-2 text-black bg-white border-2 border-gray-300">
                <option v-for="{ value, text } in evaluateOptions" :key="value" :value="value">
                    {{ text }}
                </option>
            </select>
            <div class="flex-1 text-black">
                <Multiselect
                    v-model="settings.options"
                    mode="tags"
                    :options="availableOptions"
                    :close-on-select="false"
                    :searchable="true"
                    :search-filter="customSearchFilter"
                    label="name"
                    track-by="value"
                    class="custom-multiselect"
                />
            </div>
            <select v-if="elseEnabled"
                    v-model="settings.elseType"
                    class="w-auto pr-10 p-2 text-black bg-white border-2 border-gray-300"
                    @change="addOr(settings.elseType)">
                <option></option>
                <option value="else">Else</option>
                <option value="or">Or</option>
            </select>
        </div>
        <div v-for="item in settings.orListItems" class="flex flex-1 flex-col md:flex-row">
            <select v-model="item.visibility" class="w-auto pr-10 p-2 text-black bg-white border-2 border-gray-300">
                <option v-for="{ value, text } in visibilityOptions" :key="value" :value="value">
                    {{ text }}
                </option>
            </select>
            <textarea class="flex-1" v-model="item.orText" :placeholder="item.orText"/>
            <select v-model="item.evaluate" class="w-auto pr-10 p-2 text-black bg-white border-2 border-gray-300">
                <option v-for="{ value, text } in evaluateOptions" :key="value" :value="value">
                    {{ text }}
                </option>
            </select>
            <div class="flex-1 text-black">
                <Multiselect
                    v-model="item.options"
                    mode="tags"
                    :options="availableOptions"
                    :close-on-select="false"
                    :searchable="true"
                    :search-filter="customSearchFilter"
                    label="name"
                    track-by="value"
                    class="custom-multiselect"
                />
            </div>
            <select v-if="item.elseEnabled"
                    v-model="item.elseType"
                    class="w-auto pr-10 p-2 text-black bg-white border-2 border-gray-300"
                    @change="addOr(item.elseType, item.id)">
                <option></option>
                <option value="else">Else</option>
                <option value="or">Or</option>
            </select>
        </div>
        <div v-if="settings.showElse" class="text-black">
            <HeaderInput v-if="type === 'header'" :header="settings.elseHeader"/>
            <TextInput v-if="type === 'text'" :text="settings.elseText"/>
            <ListInput v-if="type === 'list'" :list="settings.elseList"/>
        </div>
    </div>
</template>

<script>

import {TrashIcon} from "@heroicons/vue/24/outline/index.js";
import HeaderInput from "./HeaderInput.vue";
import TextInput from "./TextInput.vue";
import ListInput from "./ListInput.vue";

export default {
    components: {ListInput, TextInput, HeaderInput, TrashIcon},
    props: {
        settings: Object,
        options: Array,
        privacyOptions: Array,
        type: String,
        elseEnabled: {
            type: Boolean,
            default: true // Set the default value to true
        },
        policyType: {
            type: String,
            default: '',
        },
        policyTypes: {
            type: Object,
            default: () => ({}),
        },
        isApp: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            visibility: {
                show: 'Show',
                hide: 'Hide',
            },
            evaluate: {
                ifHas: 'If Has',
                ifNot: 'If Not',
                ifAny: 'If Any',
                ifAll: 'If All',
                ifHasPolicy: 'If Has Policy',
            },
            elseOptions: {
                else: 'Else',
                or: 'Or'
            }
        }
    },
    methods: {
        generateUniqueId() {
            return Date.now().toString(36) + "-" + Math.random().toString(36).substring(2);
        },
        addOr(type, id = null) {
            // Special handling for the initial item
            if (!id) {
                // If "else" or empty is selected from the initial item, delete all `orListItems`
                if (type === "else" || type === "") {
                    this.settings.orListItems = []; // Use null if you prefer to signify no items
                    this.settings.showElse = true;
                } else if (type === "or") {
                    // Ensure `orListItems` exists before adding new 'or' items
                    if (!this.settings.orListItems) {
                        this.settings.orListItems = [];
                    }
                    this.settings.showElse = false;
                    // Add a new 'or' condition to `orListItems`
                    this.settings.orListItems.push({
                        id: this.generateUniqueId(),
                        elseEnabled: true,
                        orText: '',
                        elseType: '',
                        visibility: 'show',
                        evaluate: 'ifHas',
                        options: []
                    });
                }
            } else {
                // Handling for list items
                const index = this.settings.orListItems.findIndex(item => item.id === id);
                if (type === "else" || type === "") {
                    // For non-initial items, remove all items after the current one
                    if (index !== -1) {
                        this.settings.orListItems = this.settings.orListItems.slice(0, index + 1);
                    }
                    this.settings.showElse = true;
                } else if (type === "or") {
                    // Add new 'or' item logic remains the same for list items
                    this.settings.orListItems.push({
                        id: this.generateUniqueId(),
                        elseEnabled: true,
                        orText: '',
                        elseType: '',
                        visibility: 'show',
                        evaluate: 'ifHas',
                        options: []
                    });
                    this.settings.showElse = false;
                }
            }
        },

        customSearchFilter(option, query, select$) {
            return option.name.toLowerCase().includes(query.toLowerCase());
        },
    },
    computed: {
        evaluateOptions() {
            return Object.entries(this.evaluate).map(([value, text]) => ({value, text}));
        },
        visibilityOptions() {
            return Object.entries(this.visibility).map(([value, text]) => ({value, text}));
        },
        availableOptions() {
            if (this.settings.evaluate === 'ifHasPolicy') {
                return Object.entries(this.policyTypes).map(([key, value]) => ({
                    value: key, // key
                    name: value, // value
                }));
            }

            let optionsMap = {};
            this.options.forEach(step => {
                step.options.forEach(option => {
                    if (option.name && !optionsMap[option.id]) {
                        optionsMap[option.id] = {
                            value: option.id,
                            name: option.name
                        };
                    }
                    option.groups.forEach(group => {
                        group.items.forEach(item => {
                            if (item.name && !optionsMap[item.id]) {
                                optionsMap[item.id] = {
                                    value: item.id,
                                    name: option.name + (group.name ? ' - ' + group.name + ' - ' : ' - ') + item.name
                                };
                            } else if (item.type === 'radio') {
                                item.radioOptions.forEach(item => {
                                    if (item.name && !optionsMap[item.id]) {
                                        optionsMap[item.id] = {
                                            value: item.id,
                                            name: option.name + (group.name ? ' - ' + group.name + ' - ' : ' - ') + item.name
                                        };
                                    }
                                })
                            }
                            item.groups.forEach(itemGroup => {
                                itemGroup.items.forEach(itemGroupItem => {
                                    if (itemGroupItem.name && !optionsMap[itemGroupItem.id]) {
                                        optionsMap[itemGroupItem.id] = {
                                            value: itemGroupItem.id,
                                            name: option.name + (group.name ? ' - ' + group.name + ' - ' : ' - ') + item.name + ' - ' + itemGroupItem.name
                                        };
                                    }
                                });
                            });
                        });
                    });
                });
            });

            if(this.privacyOptions){
                this.privacyOptions.forEach(step => {
                    step.options.forEach(option => {
                        if (option.name && !optionsMap[option.id]) {
                            optionsMap[option.id] = {
                                value: option.id,
                                name: 'PRIVACY - ' + option.name
                            };
                        }
                        option.groups.forEach(group => {
                            group.items.forEach(item => {
                                if (item.name && !optionsMap[item.id]) {
                                    optionsMap[item.id] = {
                                        value: item.id,
                                        name: 'PRIVACY - ' + option.name + (group.name ? ' - ' + group.name + ' - ' : ' - ') + item.name
                                    };
                                } else if (item.type === 'radio') {
                                    item.radioOptions.forEach(item => {
                                        if (item.name && !optionsMap[item.id]) {
                                            optionsMap[item.id] = {
                                                value: item.id,
                                                name: 'PRIVACY - ' + option.name + (group.name ? ' - ' + group.name + ' - ' : ' - ') + item.name
                                            };
                                        }
                                    })
                                }
                                item.groups.forEach(itemGroup => {
                                    itemGroup.items.forEach(itemGroupItem => {
                                        if (itemGroupItem.name && !optionsMap[itemGroupItem.id]) {
                                            optionsMap[itemGroupItem.id] = {
                                                value: itemGroupItem.id,
                                                name: 'PRIVACY - ' + option.name + (group.name ? ' - ' + group.name + ' - ' : ' - ') + item.name + ' - ' + itemGroupItem.name
                                            };
                                        }
                                    });
                                });
                            });
                        });
                    });
                });
            }


            if(this.policyType === 'cookies'){
                optionsMap['hasEssentialCookies'] = {
                    value: 'hasEssentialCookies',
                    name: 'Has Essential Cookies'
                };
                optionsMap['hasPerformanceCookies'] = {
                    value: 'hasPerformanceCookies',
                    name: 'Has Performance Cookies'
                };
                optionsMap['hasFunctionalityCookies'] = {
                    value: 'hasFunctionalityCookies',
                    name: 'Has Functionality Cookies'
                };
                optionsMap['hasTargetingAdvertisingCookies'] = {
                    value: 'hasTargetingAdvertisingCookies',
                    name: 'Has Targeting/advertising Cookies'
                };
            }
            if(this.isApp){
                optionsMap['hasWebsite'] = {
                    value: 'hasWebsite',
                    name: 'Associated Website'
                };
            }

            return Object.values(optionsMap);
        }
    }
}
</script>
<style>
/* Define custom CSS variables for styling */
.custom-multiselect {
    --ms-radius: 0; /* Remove rounded corners */
}
.custom-multiselect .multiselect-wrapper {
    min-height: 42px !important;
}
</style>
