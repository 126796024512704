<template>
    <div class="flex-1 ml-4">
        <div class="flex items-stretch">
            <div class="flex items-center justify-center p-2 bg-blue-100 rounded-l-lg drag-handle">
                <ChevronUpDownIcon class="w-6 h-6 text-black"/>
            </div>

            <input v-model="radio.name"
                   class="text-black p-2 border-2 w-full border-white border-l-gray-800"
                   type="text"
                   :placeholder="'Radio Option Title'">
            <select v-model="radio.type"
                    v-if="radio.showDescription"
                    class="w-auto pr-10 p-2 text-black bg-white border-2 border-gray-300">
                <option v-for="{ value, text } in radioTypes" :key="value" :value="value">
                    {{ text }}
                </option>
            </select>
            <input v-model="radio.description"
                   v-if="radio.showDescription"
                   class="text-black p-2 border-2 w-full border-white border-l-gray-800"
                   :placeholder="'Text Input Placeholder for Radio Option ' + (itemGroupItemRadioIndex + 1)">
            <div
                class="text-white bg-gray-700 flex items-center justify-center p-2 hover:bg-gray-800 border-t-2 border-b-2 border-r-2">
                <button @click.prevent="toggleItemGroupItemRadioDescription(stepIndex, optionIndex, groupIndex, itemIndex, itemGroupIndex, itemGroupItemIndex, itemGroupItemRadioIndex)">
                    <ChatBubbleLeftIcon class="h-5 w-5 text-white"/>
                </button>
            </div>
            <div v-if="radioCount > 2"
                 class="text-white bg-gray-700 flex items-center justify-center p-2 hover:bg-gray-800 border-t-2 border-b-2 border-r-2">
                <button @click.prevent="removeItemGroupItemRadioOption(stepIndex, optionIndex, groupIndex, itemIndex, itemGroupIndex, itemGroupItemIndex, itemGroupItemRadioIndex)">
                    <TrashIcon class="h-5 w-5 text-red-500"/>
                </button>
            </div>
        </div>
    </div>
</template>

<script>

import {ChatBubbleLeftIcon, ChevronUpDownIcon, TrashIcon} from "@heroicons/vue/24/outline/index.js";

export default {
    components: {
        ChevronUpDownIcon,
        TrashIcon,
        ChatBubbleLeftIcon
    },
    props: {
        radio: {
            type: Object,
            required: true,
        },
        radioCount: {
            type: Number,
            required: true
        },
        stepIndex: {
            type: Number,
            required: true
        },
        optionIndex: {
            type: Number,
            required: true
        },
        groupIndex: {
            type: Number,
            required: true
        },
        itemIndex: {
            type: Number,
            required: true
        },
        itemGroupIndex: {
            type: Number,
            required: true,
        },
        itemGroupItemIndex: {
            type: Number,
            required: true,
        },
        itemGroupItemRadioIndex: {
            type: Number,
            required: true
        },
    },
    data() {
        return {
            drag: false,
            radioTypes: [
                {value: 'string', text: 'String'},
                {value: 'email', text: 'Email'},
                {value: 'url', text: 'URL'}
            ],
        };
    },
    inject: [
        'removeItemGroupItemRadioOption',
        'toggleItemGroupItemRadioDescription'
    ],
};
</script>
<style>
.input-toggle img {
    filter: brightness(0) invert(1);
}
</style>
