<template>
    <div class="flex flex-col">
        <div class="text-black flex flex-1 flex-col md:flex-row">
            <select v-model="settings.visibility"
                    class="w-auto pr-10  p-2 text-black bg-white border-2 border-gray-300">
                <option v-for="{ value, text } in visibilityOptions" :key="value" :value="value">
                    {{ text }}
                </option>
            </select>
            <select v-model="settings.evaluate" class="w-auto pr-10 p-2 text-black bg-white border-2 border-gray-300">
                <option v-for="{ value, text } in evaluateOptions" :key="value" :value="value">{{ text }}</option>
            </select>
            <div class="flex-1 text-black">
                <Multiselect
                    v-model="settings.options"
                    mode="tags"
                    :options="availableOptions"
                    :close-on-select="false"
                    :searchable="true"
                    :search-filter="customSearchFilter"
                    label="name"
                    track-by="value"
                    class="custom-multiselect"
                />
            </div>
            <select v-if="elseEnabled"
                    v-model="elseType"
                    class="w-auto pr-10 p-2 text-black bg-white border-2 border-gray-300"
                    @change="addOr(elseType)">
                <option></option>
                <option value="else">Else</option>
                <option value="or">Or</option>
            </select>
        </div>
        <div v-if="elseType" class="text-black">
            <ListItemElseInput v-if="elseType === 'else'" :text="settings.elseListItem"/>
            <div v-for="orItem in settings.orListItems">
                <div class="flex flex-row text-white">
                    <select v-model="orItem.visibility"
                            class="w-auto pr-10 p-2 p-2 text-black bg-white border-2 border-gray-300">
                        <option v-for="{ value, text } in visibilityOptions" :key="value" :value="value">
                            {{ text }}
                        </option>
                    </select>
                    <textarea v-model="orItem.text.value" placeholder="Item Content"
                              class="text-black textarea-class flex-1 border-2 border-t-white h-100"></textarea>
                    <select v-model="orItem.evaluate"
                            class="w-auto pr-10 p-2 text-black bg-white border-2 border-gray-300">
                        <option v-for="{ value, text } in evaluateOptions" :key="value" :value="value">{{
                                text
                            }}
                        </option>
                    </select>
                    <div class="flex-1 text-black">
                        <Multiselect
                            v-model="orItem.options"
                            mode="tags"
                            :options="availableOptions"
                            :close-on-select="false"
                            :searchable="true"
                            :search-filter="customSearchFilter"
                            label="name"
                            track-by="value"
                            class="custom-multiselect"
                        />
                    </div>
                    <select v-if="elseEnabled"
                            v-model="orItem.else"
                            class="w-auto pr-10 p-2 p-2 text-black bg-white border-2 border-gray-300"
                            @change="addOr(orItem.else, orItem.id)">
                        <option></option>
                        <option value="else">Else</option>
                        <option value="or">Or</option>
                    </select>
                    <div v-tooltip="'Delete Step'"
                         class="text-white bg-gray-700 flex items-center justify-center p-2 hover:bg-gray-800 border-t-2 border-b-2 border-r-2">
                        <button @click.prevent="removeOrItem(orItem.id)">
                            <TrashIcon class="h-5 w-5 text-red-500"/>
                        </button>
                    </div>
                </div>
                <ListItemElseInput v-if="orItem.else === 'else'" :text="orItem.elseListItem"/>
            </div>
        </div>
    </div>
</template>

<script>
import {TrashIcon} from "@heroicons/vue/24/outline/index.js";
import ListItemElseInput from "./ListItemElseInput.vue";

export default {
    components: {
        ListItemElseInput,
        TrashIcon
    },
    props: {
        settings: Object,
        options: Array,
        privacyOptions: Array,
        elseEnabled: {
            type: Boolean,
            default: true
        },
        policyTypes: {
            type: Object,
            default: () => ({}),
        },
        isApp: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            visibilityOptions: [
                {value: 'show', text: 'Show'},
                {value: 'hide', text: 'Hide'}
            ],
            evaluateOptions: [
                {value: 'ifHas', text: 'If Has'},
                {value: 'ifNot', text: 'If Not'},
                {value: 'ifAny', text: 'If Any'},
                {value: 'ifAll', text: 'If All'},
                {value: 'ifHasPolicy', text: 'If Has Policy'},
            ],
            elseType: this.settings.else // Default selection for the dropdown
        };
    },
    methods: {
        customSearchFilter(option, query, select$) {
            return option.name.toLowerCase().includes(query.toLowerCase());
        },
        generateUniqueId() {
            return Date.now().toString(36) + "-" + Math.random().toString(36).substring(2);
        },
        addOr(type, id = null) {
            if(!id) {
                this.settings.else = type;
            }
            if (type === "or") {
                this.settings.orListItems.push({
                    id: this.generateUniqueId(),
                    visibility: 'show',
                    evaluate: 'ifHas',
                    options: null,
                    else: '',
                    elseListItem: {
                        value: '',
                    },
                    text: { value: '' }
                });
            } else {
                if (!id) {
                    // Clear all items in orListItems
                    this.settings.orListItems = [];
                } else if (this.settings.orListItems.length > 0) {
                    const index = this.settings.orListItems.findIndex(item => item.id === id);
                    if (index !== -1) {
                        this.settings.orListItems.splice(index + 1);
                    }
                }
            }
        },
        removeOrItem(id) {
            const index = this.settings.orListItems.findIndex(item => item.id === id);

            if (index !== -1) {
                this.settings.orListItems.splice(index, 1);
                if (this.settings.orListItems.length > 0) {
                    const lastIndex = this.settings.orListItems.length - 1;
                    this.settings.orListItems[lastIndex].else = '';
                } else {
                    this.elseType = '';
                }
            }
        }
    },
    computed: {
        availableOptions() {
            if (this.settings.evaluate === 'ifHasPolicy') {
                return Object.entries(this.policyTypes).map(([key, value]) => ({
                    value: key, // key
                    name: value, // value
                }));
            }
            let optionsMap = {};
            this.options.forEach(step => {
                step.options.forEach(option => {
                    if (option.name && !optionsMap[option.id]) {
                        optionsMap[option.id] = {
                            value: option.id,
                            name: option.name
                        };
                    }
                    option.groups.forEach(group => {
                        group.items.forEach(item => {
                            if (item.name && !optionsMap[item.id]) {
                                optionsMap[item.id] = {
                                    value: item.id,
                                    name: option.name + (group.name ? ' - ' + group.name + ' - ' : ' - ') + item.name
                                };
                            }
                            item.groups.forEach(itemGroup => {
                                itemGroup.items.forEach(itemGroupItem => {
                                    if (itemGroupItem.name && !optionsMap[itemGroupItem.id]) {
                                        optionsMap[itemGroupItem.id] = {
                                            value: itemGroupItem.id,
                                            name: option.name + (group.name ? ' - ' + group.name + ' - ' : ' - ') + item.name + ' - ' + itemGroupItem.name
                                        };
                                    }
                                });
                            });
                        });
                    });
                });
            });
            if(this.privacyOptions){
                this.privacyOptions.forEach(step => {
                    step.options.forEach(option => {
                        if (option.name && !optionsMap[option.id]) {
                            optionsMap[option.id] = {
                                value: option.id,
                                name: 'PRIVACY - ' + option.name
                            };
                        }
                        option.groups.forEach(group => {
                            group.items.forEach(item => {
                                if (item.name && !optionsMap[item.id]) {
                                    optionsMap[item.id] = {
                                        value: item.id,
                                        name: 'PRIVACY - ' + option.name + (group.name ? ' - ' + group.name + ' - ' : ' - ') + item.name
                                    };
                                }
                                item.groups.forEach(itemGroup => {
                                    itemGroup.items.forEach(itemGroupItem => {
                                        if (itemGroupItem.name && !optionsMap[itemGroupItem.id]) {
                                            optionsMap[itemGroupItem.id] = {
                                                value: itemGroupItem.id,
                                                name: 'PRIVACY - ' + option.name + (group.name ? ' - ' + group.name + ' - ' : ' - ') + item.name + ' - ' + itemGroupItem.name
                                            };
                                        }
                                    });
                                });
                            });
                        });
                    });
                });
            }

            if(this.isApp){
                optionsMap['hasWebsite'] = {
                    value: 'hasWebsite',
                    name: 'Associated Website'
                };
            }

            return Object.values(optionsMap);
        }
    }
};
</script>

<style>
/* Define custom CSS variables for styling */
.custom-multiselect {
    --ms-radius: 0; /* Remove rounded corners */
    height: 100%;
}

.custom-multiselect .multiselect-wrapper {
    min-height: 42px !important;
}
.multiselect-tag-wrapper {
    display: inline-block;
    max-width: 300px; /* Adjust this width to fit your design */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    direction: rtl;
    text-align: left;
}
</style>
