<template>
    <div>
        <div class="flex justify-between items-center bg-gray-800 py-4 px-4">
            <h2 class="font-bold text-xl text-white">Policy Form</h2>
            <button @click.prevent="toggleAllVisibility()">
                <ArrowUpTrayIcon v-if="isContentVisible" class="h-5 w-5 text-white"/>
                <ArrowDownTrayIcon v-else class="h-5 w-5 text-white"/>
            </button>
        </div>
        <transition name="expand" @before-enter="beforeEnter" @enter="enter" @leave="leave">
            <div v-if="isContentVisible">
                <draggable v-model="steps" item-key="id" @start="drag=true" @end="drag=false" handle=".drag-handle">
                    <template #item="{ element, index }">
                        <StepComponent
                            :key="element.id"
                            :step="element"
                            :stepIndex="index"
                            :content="form"
                            :languages="languages"
                            :free="free"
                        />
                    </template>
                </draggable>
                <div class="flex">
                    <button @click.prevent="addStep()"
                            class="mt-4 flex items-center justify-center space-x-2 bg-green-800 hover:bg-green-600 text-white font-semibold py-1 px-4 border border-green-800 rounded shadow hover:shadow-md transition-colors duration-150">
                        <span>Add Step</span>
                    </button>
                    <button @click.prevent="togglePreview"
                            class="ml-2 mt-4 flex items-center justify-center space-x-2 bg-blue-800 hover:bg-blue-600 text-white font-semibold py-1 px-4 border border-blue-800 rounded shadow hover:shadow-md transition-colors duration-150">
                        <span>Preview Form</span>
                    </button>
                </div>
            </div>
        </transition>
        <FormPreview v-if="preview" :form="form" :multipart="multipart" @close="togglePreview"></FormPreview>
    </div>
</template>

<script>
import draggable from 'vuedraggable';
import axios from "axios";

import {
    ArchiveBoxXMarkIcon,
    ArrowDownTrayIcon,
    ArrowRightCircleIcon,
    ArrowUpTrayIcon,
    ChatBubbleLeftIcon,
    CheckCircleIcon,
    ChevronUpDownIcon,
    Cog6ToothIcon,
    TrashIcon,
    ListBulletIcon,
    PlusCircleIcon
} from "@heroicons/vue/24/outline"

import OptionComponent from './OptionComponent.vue';
import StepComponent from "./StepComponent.vue";
import {debounce} from "lodash";
import FormPreview from "./FormPreview.vue";

export default {
    components: {
        FormPreview,
        StepComponent,
        ArchiveBoxXMarkIcon,
        ArrowDownTrayIcon,
        ArrowRightCircleIcon,
        ArrowUpTrayIcon,
        ChatBubbleLeftIcon,
        CheckCircleIcon,
        ChevronUpDownIcon,
        Cog6ToothIcon,
        draggable,
        TrashIcon,
        ListBulletIcon,
        PlusCircleIcon,
        OptionComponent,
    },
    props: {
        type: {
            type: String,
            default: null
        },
        form: {
            type: Array,
            default: () => [],
        },
        multipart: {
            type: Boolean,
            default: false
        },
        languages: {
            type: Array,
            default: () => [],
        }
    },
    data() {
        return {
            drag: false,
            isContentVisible: true,
            steps: this.form || [],
            preview: false,
            free: this.type === "website-free"
        };
    },
    provide() {
        return {
            addOption: this.addOption,
            addGroup: this.addGroup,
            addItem: this.addItem,
            addItemGroup: this.addItemGroup,
            addItemGroupItem: this.addItemGroupItem,
            addItemGroupItemRadioOption: this.addItemGroupItemRadioOption,
            addRadioOption: this.addRadioOption,
            deleteGroup: this.deleteGroup,
            deleteItemGroup: this.deleteItemGroup,
            removeItem: this.removeItem,
            removeItemGroupItem: this.removeItemGroupItem,
            removeItemGroupItemRadioOption: this.removeItemGroupItemRadioOption,
            removeOption: this.removeOption,
            removeRadioOption: this.removeRadioOption,
            removeStep: this.removeStep,
            toggleGroupContentVisibility: this.toggleGroupContentVisibility,
            toggleItemContentVisibility: this.toggleItemContentVisibility,
            toggleItemDefaultState: this.toggleItemDefaultState,
            toggleItemDescription: this.toggleItemDescription,
            toggleItemDisplayText: this.toggleItemDisplayText,
            toggleItemValidation: this.toggleItemValidation,
            toggleItemGroupContentVisibility: this.toggleItemGroupContentVisibility,
            toggleItemGroupItemContentVisibility: this.toggleItemGroupItemContentVisibility,
            toggleItemGroupItemDescription: this.toggleItemGroupItemDescription,
            toggleItemGroupItemRadioDescription: this.toggleItemGroupItemRadioDescription,
            toggleItemGroupItemValidation: this.toggleItemGroupItemValidation,
            toggleOptionContentVisibility: this.toggleOptionContentVisibility,
            toggleOptionDefaultState: this.toggleOptionDefaultState,
            toggleOptionDisabledOnForm: this.toggleOptionDisabledOnForm,
            toggleOptionDescription: this.toggleOptionDescription,
            toggleStepContentVisibility: this.toggleStepContentVisibility,
            toggleStepDefaultState: this.toggleStepDefaultState,
            toggleStepDescription: this.toggleStepDescription,
            toggleStepValidation: this.toggleStepValidation,
        };
    },
    methods: {
        generateUniqueId() {
            const timePart = Date.now().toString(36);
            const randomPart = Math.random().toString(36).substring(2, 15);
            return `${timePart}-${randomPart}`;
        },
        // Component Generators
        generateNewStep() {
            return {
                id: this.generateUniqueId(),
                name: '',
                description: '',
                descriptionVisible: false,
                options: [],
                contentVisible: true,
                validationVisible: false,
                validation: {
                    rule: 'required',
                    options: []
                },
                isRegional: false
            };
        },
        generateNewOption() {
            return {
                id: this.generateUniqueId(),
                name: '',
                description: '',
                descriptionVisible: false,
                groups: [],
                contentVisible: true,
            }
        },
        generateNewGroup() {
            return {
                id: this.generateUniqueId(),
                name: '',
                items: [this.generateNewItem()],
                contentVisible: true
            };
        },
        generateNewItem() {
            let displayTexts = {};
            for (let language of this.languages) {
                displayTexts[language.slug] = '';
            }
            return {
                id: this.generateUniqueId(),
                name: '',
                addOwn: false,
                type: 'checkbox',
                span: 'half',
                description: '',
                showDescription: false,
                displayText: displayTexts,
                showDisplayText: false,
                contentVisible: true,
                validationVisible: false,
                radioOptions: [],
                groups:[],
                validation: {
                    rule: 'required',
                    options: []
                }
            };
        },
        generateNewRadioOption() {
            return {
                id: this.generateUniqueId(),
                type: 'string',
                name: '',
                description: '',
                showDescription: false
            };
        },
        addStep() {
            this.steps.push(this.generateNewStep());
        },

        // Step Component Functions
        removeStep(stepIndex) {
            if (confirm('Are you sure you want to remove this step?')) {
                this.steps.splice(stepIndex, 1);
            }
        },
        toggleStepDefaultState(stepIndex) {
            this.steps[stepIndex].defaultChecked = !this.steps[stepIndex].defaultChecked;
        },
        toggleStepDescription(stepIndex) {
            this.steps[stepIndex].descriptionVisible = !this.steps[stepIndex].descriptionVisible;
        },
        toggleStepValidation(stepIndex) {
            this.steps[stepIndex].validationVisible = !this.steps[stepIndex].validationVisible;
        },
        toggleStepContentVisibility(stepIndex) {
            this.steps[stepIndex].contentVisible = !this.steps[stepIndex].contentVisible;
        },
        addOption(stepIndex) {
            this.steps[stepIndex].options.push(this.generateNewOption());
        },

        // Option Component Functions
        removeOption(stepIndex, optionIndex) {
            if (confirm('Are you sure you want to remove option "' + this.steps[stepIndex].options[optionIndex].name + '"?')) {
                this.steps[stepIndex].options.splice(optionIndex, 1);
            }
        },
        toggleOptionDefaultState(stepIndex, optionIndex) {
            this.steps[stepIndex].options[optionIndex].defaultChecked = !this.steps[stepIndex].options[optionIndex].defaultChecked
        },
        toggleOptionDisabledOnForm(stepIndex, optionIndex) {
            this.steps[stepIndex].options[optionIndex].disabledOnForm = !this.steps[stepIndex].options[optionIndex].disabledOnForm
        },
        toggleOptionDescription(stepIndex, optionIndex) {
            this.steps[stepIndex].options[optionIndex].descriptionVisible = !this.steps[stepIndex].options[optionIndex].descriptionVisible;
            if (this.steps[stepIndex].options[optionIndex].descriptionVisible) {
                this.steps[stepIndex].options[optionIndex].contentVisible = true;
            }
        },
        toggleOptionContentVisibility(stepIndex, optionIndex) {
            this.steps[stepIndex].options[optionIndex].contentVisible = !this.steps[stepIndex].options[optionIndex].contentVisible;
            this.steps[stepIndex].options[optionIndex].showDescription = this.steps[stepIndex].options[optionIndex].contentVisible;
        },
        addGroup(stepIndex, optionIndex) {
            this.steps[stepIndex].options[optionIndex].groups.push(this.generateNewGroup());
            this.steps[stepIndex].options[optionIndex].contentVisible = true;
        },

        // Group Component Functions
        deleteGroup(stepIndex, optionIndex, groupIndex) {
            this.steps[stepIndex].options[optionIndex].groups.splice(groupIndex, 1);
        },
        toggleGroupContentVisibility(stepIndex, optionIndex, groupIndex) {
            this.steps[stepIndex].options[optionIndex].groups[groupIndex].contentVisible = !this.steps[stepIndex].options[optionIndex].groups[groupIndex].contentVisible;
        },
        addItem(stepIndex, optionIndex, groupIndex) {
            this.steps[stepIndex].options[optionIndex].groups[groupIndex].items.push(this.generateNewItem());
        },

        // Item Component Functions
        removeItem(stepIndex, optionIndex, groupIndex, itemIndex) {
            this.steps[stepIndex].options[optionIndex].groups[groupIndex].items.splice(itemIndex, 1);
        },
        toggleItemDefaultState(stepIndex, optionIndex, groupIndex, itemIndex) {
            this.steps[stepIndex].options[optionIndex].groups[groupIndex].items[itemIndex].defaultChecked = !this.steps[stepIndex].options[optionIndex].groups[groupIndex].items[itemIndex].defaultChecked
        },
        toggleItemDescription(stepIndex, optionIndex, groupIndex, itemIndex) {
            this.steps[stepIndex].options[optionIndex].groups[groupIndex].items[itemIndex].showDescription = !this.steps[stepIndex].options[optionIndex].groups[groupIndex].items[itemIndex].showDescription
            if(this.steps[stepIndex].options[optionIndex].groups[groupIndex].items[itemIndex].showDescription){
                this.steps[stepIndex].options[optionIndex].groups[groupIndex].items[itemIndex].contentVisible = true;
            }
        },
        toggleItemDisplayText(stepIndex, optionIndex, groupIndex, itemIndex) {
            this.steps[stepIndex].options[optionIndex].groups[groupIndex].items[itemIndex].showDisplayText = !this.steps[stepIndex].options[optionIndex].groups[groupIndex].items[itemIndex].showDisplayText
        },
        toggleItemValidation(stepIndex, optionIndex, groupIndex, itemIndex) {
            this.steps[stepIndex].options[optionIndex].groups[groupIndex].items[itemIndex].validationVisible = !this.steps[stepIndex].options[optionIndex].groups[groupIndex].items[itemIndex].validationVisible
        },
        toggleItemContentVisibility(stepIndex, optionIndex, groupIndex, itemIndex) {
            this.steps[stepIndex].options[optionIndex].groups[groupIndex].items[itemIndex].contentVisible = !this.steps[stepIndex].options[optionIndex].groups[groupIndex].items[itemIndex].contentVisible
        },
        addRadioOption(stepIndex, optionIndex, groupIndex, itemIndex, count = 1) {
            const radioOptions = this.steps[stepIndex].options[optionIndex].groups[groupIndex].items[itemIndex].radioOptions;
            for (let i = 0; i < count; i++) {
                radioOptions.push(this.generateNewRadioOption());
            }
        },
        addItemGroup(stepIndex, optionIndex, groupIndex, itemIndex) {
            this.steps[stepIndex].options[optionIndex].groups[groupIndex].items[itemIndex].groups.push(this.generateNewGroup());
            this.steps[stepIndex].options[optionIndex].groups[groupIndex].items[itemIndex].contentVisible = true;
        },

        // Radio Component Functions
        removeRadioOption(stepIndex, optionIndex, groupIndex, itemIndex, radioIndex) {
            this.steps[stepIndex].options[optionIndex].groups[groupIndex].items[itemIndex].radioOptions.splice(radioIndex, 1);
        },

        // Item Group Component Functions
        deleteItemGroup(stepIndex, optionIndex, groupIndex, itemIndex, itemGroupIndex) {
            this.steps[stepIndex].options[optionIndex].groups[groupIndex].items[itemIndex].groups.splice(itemGroupIndex, 1);
        },
        toggleItemGroupContentVisibility(stepIndex, optionIndex, groupIndex, itemIndex, itemGroupIndex) {
            this.steps[stepIndex].options[optionIndex].groups[groupIndex].items[itemIndex].groups[itemGroupIndex].contentVisible = !this.steps[stepIndex].options[optionIndex].groups[groupIndex].items[itemIndex].groups[itemGroupIndex].contentVisible;
        },
        addItemGroupItem(stepIndex, optionIndex, groupIndex, itemIndex, itemGroupIndex) {
            this.steps[stepIndex].options[optionIndex].groups[groupIndex].items[itemIndex].groups[itemGroupIndex].items.push(this.generateNewItem());
        },

        // Item Group Item Component Functions
        removeItemGroupItem(stepIndex, optionIndex, groupIndex, itemIndex, itemGroupIndex, itemGroupItemIndex) {
            this.steps[stepIndex].options[optionIndex].groups[groupIndex].items[itemIndex].groups[itemGroupIndex].items.splice(itemGroupItemIndex, 1);
        },
        toggleItemGroupItemDescription(stepIndex, optionIndex, groupIndex, itemIndex, itemGroupIndex, itemGroupItemIndex) {
            this.steps[stepIndex].options[optionIndex].groups[groupIndex].items[itemIndex].groups[itemGroupIndex].items[itemGroupItemIndex].showDescription = !this.steps[stepIndex].options[optionIndex].groups[groupIndex].items[itemIndex].groups[itemGroupIndex].items[itemGroupItemIndex].showDescription;
        },
        toggleItemGroupItemContentVisibility(stepIndex, optionIndex, groupIndex, itemIndex, itemGroupIndex, itemGroupItemIndex) {
            this.steps[stepIndex].options[optionIndex].groups[groupIndex].items[itemIndex].groups[itemGroupIndex].items[itemGroupItemIndex].contentVisible = !this.steps[stepIndex].options[optionIndex].groups[groupIndex].items[itemIndex].groups[itemGroupIndex].items[itemGroupItemIndex].contentVisible;
        },
        toggleItemGroupItemValidation(stepIndex, optionIndex, groupIndex, itemIndex, itemGroupIndex, itemGroupItemIndex) {
            this.steps[stepIndex].options[optionIndex].groups[groupIndex].items[itemIndex].groups[itemGroupIndex].items[itemGroupItemIndex].validationVisible = !this.steps[stepIndex].options[optionIndex].groups[groupIndex].items[itemIndex].groups[itemGroupIndex].items[itemGroupItemIndex].validationVisible;
        },
        addItemGroupItemRadioOption(stepIndex, optionIndex, groupIndex, itemIndex, itemGroupIndex, itemGroupItemIndex) {
            const radioOptions = this.steps[stepIndex].options[optionIndex].groups[groupIndex].items[itemIndex].groups[itemGroupIndex].items[itemGroupItemIndex].radioOptions;
            radioOptions.push(this.generateNewRadioOption());
        },

        // Item Group Item Radio Component Functions
        removeItemGroupItemRadioOption(stepIndex, optionIndex, groupIndex, itemIndex, itemGroupIndex, itemGroupItemIndex, itemGroupItemRadioIndex) {
            this.steps[stepIndex].options[optionIndex].groups[groupIndex].items[itemIndex].groups[itemGroupIndex].items[itemGroupItemIndex].radioOptions.splice(itemGroupItemRadioIndex, 1);
        },
        toggleItemGroupItemRadioDescription(stepIndex, optionIndex, groupIndex, itemIndex, itemGroupIndex, itemGroupItemIndex, itemGroupItemRadioIndex) {
            this.steps[stepIndex].options[optionIndex].groups[groupIndex].items[itemIndex].groups[itemGroupIndex].items[itemGroupItemIndex].radioOptions[itemGroupItemRadioIndex].showDescription = !this.steps[stepIndex].options[optionIndex].groups[groupIndex].items[itemIndex].groups[itemGroupIndex].items[itemGroupItemIndex].radioOptions[itemGroupItemRadioIndex].showDescription;
        },

        // Visibility Toggling
        toggleAllVisibility() {
            this.isContentVisible = !this.isContentVisible;
        },
        beforeEnter(el) {
            el.style.height = '0';
            el.style.opacity = '0';
        },
        enter(el, done) {
            this.$nextTick(() => {
                el.style.transition = 'height 0.5s ease';
                el.style.height = `${el.scrollHeight}px`;
                el.style.opacity = '1';
                el.addEventListener('transitionend', () => {
                    el.style.height = null; // Remove fixed height after transition to allow for responsive changes
                    done();
                });
            });
        },
        leave(el, done) {
            el.style.height = `${el.scrollHeight}px`;
            el.style.opacity = '1';
            // Force reflow, making sure the transition starts from the current height
            getComputedStyle(el).height;
            setTimeout(() => {
                el.style.transition = 'height 0.5s ease';
                el.style.height = '0';
                el.style.opacity = '0';
                el.addEventListener('transitionend', done);
            });
        },
        togglePreview(){
            this.preview = !this.preview
        }
    },
    // Every time "steps" are altered, we sync the change to the livewire "#policy_form" input on the PolicyResource
    watch: {
        steps: {
            deep: true,
            handler(newVal) {
                const input = document.getElementById('policy_form');
                if (input) {
                    input.value = JSON.stringify(newVal);
                    input.dispatchEvent(new Event('input'));
                }
            }
        }
    }
};
</script>
