<template>
    <div class="flex flex-1 flex-col">
        <draggable v-model="contentData"
                   class="drag-area"
                   handle=".drag-handle"
                   item-key="id"
                   @start="drag=true"
                   @end="onDragEnd()">
            <template #item="{ element, index }">
                <div :key="index" :class="['mt-2']">
                    <div class="flex flex-row justify-end">
                        <div class="flex flex-row px-2 bg-blue-100 drag-handle rounded-t items-center justify-center">
                            <ChevronUpDownIcon class="w-6 h-6 text-black"/>
                            <div class="text-lg text-black text-center p-2">
                                {{ ucfirst(element.type) }}
                            </div>
                        </div>
                        <div v-if="type != 'privacy_contact' && !element.intro" v-tooltip="'Validation Settings'"
                             :class="['text-white flex items-center justify-center p-2 hover:bg-gray-800 border-t-2 border-b-2 border-r-2 max-h-[42px]', element.showSettings ? 'bg-gray-800' : 'bg-gray-700' ]">
                            <button @click.prevent="toggleSettings(element.id)">
                                <Cog6ToothIcon class="h-5 w-5 text-white-500"/>
                            </button>
                        </div>
                        <div v-tooltip="'Delete Step'"
                             :class="['text-white bg-gray-700 flex items-center justify-center p-2 hover:bg-gray-800 border-t-2 border-b-2 border-r-2 max-h-[42px]']">
                            <button @click.prevent="removeContent(element.id)">
                                <TrashIcon class="h-5 w-5 text-red-500"/>
                            </button>
                        </div>
                    </div>
                    <div :class="[element.showSettings && !element.intro ? 'border-4 border-warning-400' : '']">
                        <div  class='flex items-stretch text-gray-800'>
                            <HeaderInput v-if="element.header" :header="element.header" :type="type"/>
                            <TextInput v-if="element.text" :text="element.text"/>
                            <EvaluatedTextInput
                                v-if="element.intro"
                                :element="element"
                                :generateUniqueId="this.generateUniqueId"
                                :policy-data="policyData"
                                :privacy-data="privacyData"
                                :policy-type="policyType"
                                :policy-types="policyTypes"
                                :is-app="isApp"
                            />
                            <ListInput
                                v-if="element.list"
                                :policy-data="policyData"
                                :privacy-data="privacyData"
                                :list="element.list"
                                :policy-types="policyTypes"
                                :is-app="isApp"
                            />
                        </div>
                        <div>
                            <div v-if="!element.intro && element.showSettings">
                                <!-- Content settings component -->
                                <content-settings
                                    :settings="element.settings"
                                    :options="policyData"
                                    :privacy-options="privacyData"
                                    :type="element.type"
                                    :policy-type="policyType"
                                    :policy-types="policyTypes"
                                    :is-app="isApp"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </draggable>

        <!-- Add section buttons -->
        <div class="flex flex-row items-start">
            <button v-if="!isRegional && !isDataProtection" @click.prevent="addHeader"
                    class="mr-1 mt-4 flex items-center justify-center space-x-2 bg-green-800 hover:bg-green-600 text-white font-semibold py-1 px-4 border border-green-800 rounded shadow hover:shadow-md transition-colors duration-150">
                Add Header
            </button>
            <button v-if="!isRegional && !isDataProtection" @click.prevent="addText"
                    class="mr-1 mt-4 flex items-center justify-center space-x-2 bg-green-800 hover:bg-green-600 text-white font-semibold py-1 px-4 border border-green-800 rounded shadow hover:shadow-md transition-colors duration-150">
                Add Text
            </button>
            <button v-if="!isRegional && !isDataProtection" @click.prevent="addEvaluatedText"
                    v-tooltip="'Add a sentence with intro, outro, and items separated by a comma'"
                    class="mr-1 mt-4 flex items-center justify-center space-x-2 bg-green-800 hover:bg-green-600 text-white font-semibold py-1 px-4 border border-green-800 rounded shadow hover:shadow-md transition-colors duration-150">
                Add Evaluated Text
            </button>
            <button v-if="type !== 'privacy_contact' && !isRegional && !isDataProtection" @click.prevent="addList"
                    class="mr-1 mt-4 flex items-center justify-center space-x-2 bg-green-800 hover:bg-green-600 text-white font-semibold py-1 px-4 border border-green-800 rounded shadow hover:shadow-md transition-colors duration-150">
                Add List
            </button>
            <div v-if="type !== 'privacy_contact'" class="flex flex-1 justify-end">
                <div v-if="!isDataProtection && (isRegional || !hasRegional)" class="flex flex-col items-end">
                    <p v-if="isRegional" class="text-white w-full">Available regional policies will be displayed in this section</p>
                    <button v-if="!content.length" @click.prevent="toggleRegional"
                            class="mr-1 mt-4 flex items-center justify-center space-x-2 bg-warning-400 hover:bg-warning-500 text-white font-semibold py-1 px-4 border border-warning-400 rounded shadow hover:shadow-md transition-colors duration-150">
                        Regional Policies
                    </button>
                </div>
                <div v-if="!isRegional && (isDataProtection || !hasDataProtection)" class="flex flex-col items-end">
                    <p v-if="isDataProtection" class="text-white w-full">Data Protection contact details will be displayed in this section</p>
                    <button v-if="!content.length" @click.prevent="toggleDataProtection"
                            class="mr-1 mt-4 flex items-center justify-center space-x-2 bg-warning-400 hover:bg-warning-500 text-white font-semibold py-1 px-4 border border-warning-400 rounded shadow hover:shadow-md transition-colors duration-150">
                        Data Protection Contact
                    </button>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import HeaderInput from './HeaderInput.vue';
import TextInput from './TextInput.vue';
import ListInput from './ListInput.vue';
import ContentSettings from "./ContentSettings.vue";
import {
    TrashIcon,
    Cog6ToothIcon,
    ChevronUpDownIcon
} from "@heroicons/vue/24/outline"

import draggable from "vuedraggable";
import EvaluatedTextInput from "./EvaluatedTextInput.vue";

export default {
    components: {
        EvaluatedTextInput,
        draggable,
        ChevronUpDownIcon,
        HeaderInput,
        TextInput,
        ListInput,
        TrashIcon,
        Cog6ToothIcon,
        ContentSettings
    },
    props: {
        index: {
            type: Number,
            required: true,
        },
        type: {
            type: String,
            required: true,
        },
        content: {
            type: Array,
            default: () => [],
        },
        policyData: {
            type: Array,
            default: () => [],
        },
        privacyData: {
            type: Array,
            default: () => [],
        },
        policyType: {
            type: String,
            default: '',
        },
        isRegional: {
            type: Boolean,
            default: false
        },
        hasRegional: {
            type: Boolean,
            default: false
        },
        isDataProtection: {
            type: Boolean,
            default: false
        },
        hasDataProtection: {
            type: Boolean,
            default: false
        },
        policyTypes: {
            type: Object,
            default: () => ({}),
        },
        isApp: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            contentData: this.content,
            evaluateOptions: this.availableOptions,
            drag: false,
        }
    },
    methods: {
        onDragEnd() {
            this.drag = false;
            this.$emit('update:content', this.contentData);
        },
        generateUniqueId() {
            return Date.now().toString(36) + "-" + Math.random().toString(36).substring(2);
        },
        addHeader() {
            this.contentData.push({
                id: this.generateUniqueId(),
                type: 'header',
                showSettings: false,
                settings: {
                    visibility: '',
                    evaluate: '',
                    options: null,
                    else: '',
                    elseHeader: {
                        type: this.type === 'privacy_contact' ? 'h3' : 'h2',
                        value: ''
                    },
                    elseText: {
                        value: ''
                    },
                    elseList: {
                        value: '',
                        items: []
                    }
                },
                header: {
                    type: this.type === 'privacy_contact' ? 'h3' : 'h2',
                    value: ''
                }
            });
        },
        addText() {
            this.contentData.push({
                id: this.generateUniqueId(),
                type: 'text',
                showSettings: false,
                settings: {
                    visibility: '',
                    evaluate: '',
                    options: null,
                    else: '',
                    elseHeader: {
                        type: 'h2',
                        value: ''
                    },
                    elseText: {
                        value: ''
                    },
                    elseList: {
                        value: '',
                        items: []
                    }
                },
                text: {
                    value: ''
                }
            });
        },
        addEvaluatedText() {
            this.contentData.push({
                id: this.generateUniqueId(),
                type: 'evaluatedText',
                showSettings: false,
                intro: {
                    value: ''
                },
                evaluatedItems: [
                    {
                        id: this.generateUniqueId(),
                        type: 'text',
                        showSettings: false,
                        settings: {
                            visibility: '',
                            evaluate: '',
                            options: null
                        },
                        text: {
                            value: ''
                        }
                    }
                ],
                outro: {
                    value: ''
                },
            });
        },
        addList() {
            this.contentData.push({
                id: this.generateUniqueId(),
                type: 'list',
                showSettings: false,
                settings: {
                    visibility: '',
                    evaluate: '',
                    options: null,
                    else: '',
                    elseHeader: {
                        type: 'h2',
                        value: ''
                    },
                    elseText: {
                        value: ''
                    },
                    elseList: {
                        value: '',
                        items: []
                    }
                },
                list: {
                    listType: 'ul',
                    orderType: '1',
                    value: '',
                    items: []
                }
            });
        },
        removeContent(id) {
            const index = this.contentData.findIndex(item => item.id === id);
            if (index !== -1) {
                this.contentData.splice(index, 1);
            }
        },
        toggleSettings(id) {
            const index = this.contentData.findIndex(item => item.id === id);
            if (index !== -1) {
                this.contentData[index].showSettings = !this.contentData[index].showSettings;
            }
        },
        toggleRegional() {
            this.$emit('toggle-regional');
        },
        toggleDataProtection() {
            this.$emit('toggle-data-protection');
        },
        ucfirst(string) {
            if (!string) return '';
            return string.charAt(0).toUpperCase() + string.slice(1);
        },
    }
};
</script>
