<template>
    <label :for="item.id">{{ item.name }}</label>
    <textarea type="text"
              :name="item.id"
              :id="item.id"
              v-model="textAreaValue"
              :disabled="isDisabled"
    />
    <span v-if="errors[item.id]" class="field-error">
        {{ errors[item.id][0] }}
    </span>
</template>

<script>
export default {
    props: {
        item: {
            type: Object,
            required: true
        },
        old: {
            type: Object,
            default: () => ({})
        },
        errors: {
            type: Object,
            default: () => ({})
        },
        isDisabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            textAreaValue: this.errors[this.item.id] ? (this.old[this.item.id] || '') : (this.old[this.item.id] || this.item.description || '')
        };
    },
    watch: {
        textAreaValue(newValue) {
            this.clearError(this.item.id);
            this.$emit('input', {id: this.item.id, value: newValue});
        }
    },
    methods: {
        clearError(field) {
            this.errors[field] = null;
        }
    }
};
</script>
